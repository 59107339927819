export const enum NavigationRoute {
  ADMIN = '/admin',
  ADMIN_ADD_INTERVIEW_TYPE = '/admin/newInterviewType',
  ADMIN_EDIT_INTERVIEW_TYPE = '/admin/newInterviewType/:id',
  ADMIN_SEE_ALL_INTERVIEWS = '/admin/allInterviews',
  ADMIN_SEE_ALL_TAKER = '/admin/takers/all',
  ADMIN_SEE_ALL_REQUESTED_TAKER = '/admin/takers/requested',
  ADMIN_SEE_ALL_REJECTED_TAKER = '/admin/takers/rejected',
  ADMIN_TAKER_DETAILS = '/admin/takers/:id',
  ADMIN_ADD_INTERVIEW_BALANCE_CARD_TAG = '/admin/balanceCard/tag',
  ADMIN_EDIT_INTERVIEW_BALANCE_CARD_TAG = '/admin/balanceCard/tag/:id',
  ADMIN_SEE_ALL_INTERVIEW_BALANCE_CARD_TAGS = '/admin/balanceCard/tag/all',
  ADMIN_ADD_INTERVIEW_BALANCE_CARD = '/admin/balanceCard',
  ADMIN_EDIT_INTERVIEW_BALANCE_CARD = '/admin/balanceCard/:id',
  ADMIN_SEE_ALL_INTERVIEW_BALANCE_CARDS = '/admin/balanceCard/all',
  ADMIN_INTERVIEW_QUESTION_INSERTION = '/admin/interview/question/insert',
  DEFAULT = '/',
  ABOUT = '/about',
  CONTACT_US = '/contactus',
  SIGN_UP = '/signup',
  LOG_IN = '/login',
  HOME_PAGE = '/homepage',
  INTERVIEW_HISTORY = '/history',
  TAKE_INTERVIEW = '/interview',
  PLAN = '/plan',
  PROFILE = '/profile',
  INTERVIEW_CONNECTING = '/interview/:interviewID/connecting',
  INTERVIEW_JOIN = '/interview/:id/join',
  INTERVIEW_REVIEW = '/interview/:id/review',
  INTERVIEW_FEEDBACK = '/interview/:id/feedback',
  INTERVIEW_RECORDING = '/interview/:interviewID/recording',
  INTERVIEW = '/interview/:interviewID',
  TEST_PAGE = '/testpage',
  SETTINGS = '/settings/*',
  SETTING_PAYMENT_CARD = '/settings/payment/card',
  SETTING_PAYMENT_HISTORY = '/settings/payment/history',
  SETTING_DEVICE_MANAGE = '/settings/device/manage',
  PRIVACY_POLICY = '/privacy&policy',
}
