/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RefreshableAuthenticationResponseDto,
  SignupDto,
  SignupRequestDto,
  SignupResponseDto,
} from '../models/index';
import {
    RefreshableAuthenticationResponseDtoFromJSON,
    RefreshableAuthenticationResponseDtoToJSON,
    SignupDtoFromJSON,
    SignupDtoToJSON,
    SignupRequestDtoFromJSON,
    SignupRequestDtoToJSON,
    SignupResponseDtoFromJSON,
    SignupResponseDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface PrepareRequest {
    signupRequestDto: SignupRequestDto;
}

export interface ResendVerificationCodeRequest {
    signupRequestDto: SignupRequestDto;
}

export interface SignupRequest {
    signupDto: SignupDto;
}

/**
 * 
 */
export class SignupControllerApi extends runtime.BaseAPI {

    /**
     * Generates OTP and sends email
     * Request for signup
     */
    async prepareRaw(requestParameters: PrepareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignupResponseDto>> {
        if (requestParameters['signupRequestDto'] == null) {
            throw new runtime.RequiredError(
                'signupRequestDto',
                'Required parameter "signupRequestDto" was null or undefined when calling prepare().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/signup/prepare`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupRequestDtoToJSON(requestParameters['signupRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => SignupResponseDtoFromJSON(jsonValue));
    }

    /**
     * Generates OTP and sends email
     * Request for signup
     */
    async prepare(requestParameters: PrepareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignupResponseDto> {
        const response = await this.prepareRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates OTP and resends email
     * Resends OTP
     */
    async resendVerificationCodeRaw(requestParameters: ResendVerificationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignupResponseDto>> {
        if (requestParameters['signupRequestDto'] == null) {
            throw new runtime.RequiredError(
                'signupRequestDto',
                'Required parameter "signupRequestDto" was null or undefined when calling resendVerificationCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/signup/resend`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupRequestDtoToJSON(requestParameters['signupRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => SignupResponseDtoFromJSON(jsonValue));
    }

    /**
     * Generates OTP and resends email
     * Resends OTP
     */
    async resendVerificationCode(requestParameters: ResendVerificationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignupResponseDto> {
        const response = await this.resendVerificationCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Signs up with verified session
     * Signup
     */
    async signupRaw(requestParameters: SignupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshableAuthenticationResponseDto>> {
        if (requestParameters['signupDto'] == null) {
            throw new runtime.RequiredError(
                'signupDto',
                'Required parameter "signupDto" was null or undefined when calling signup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupDtoToJSON(requestParameters['signupDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshableAuthenticationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Signs up with verified session
     * Signup
     */
    async signup(requestParameters: SignupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshableAuthenticationResponseDto> {
        const response = await this.signupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
