import React, { useState } from 'react'
import { Menu } from '@mui/material'
import Line from '../util/Line'
import CustomLink from '../util/CustomLink'
import rightArrow from '../svg/resources/Expand_right_light (1).svg'
import ProfilePicture from '../profilePicture/ProfilePicture'
import ColoredText from '../text/ColoredText'
import { AuthManager } from '../../manager/AuthManager'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import { useNavigate } from 'react-router-dom'
import ApiManager from '../../manager/ApiManager'
import { SessionControllerApi } from '../../api/ic'

interface ProfileMenuProps {
  imgURI: 'DEFAULT' | string
  firstname: string
  lastname: string
  main: any
  secondary: any
  inverse?: boolean

}

function ProfileMenu (props: ProfileMenuProps) {
  const [isOpened, setIsOpened] = useState(null)
  const navigate = useNavigate()
  const handleClose = () => {
    setIsOpened(null)
  }

  const sessionApi = ApiManager.getInstance(SessionControllerApi)

  const Main = () => (
    props.main.map((el: any, idx: any) => (
      <CustomLink
        href={el.route}
        key={idx}
        onClick={() => { setIsOpened(null) }}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: el.color,
          textDecoration: 'none'
        }}>
        <span>{el.value}</span>
        <span style={{ userSelect: 'none' }}>
          <img
            src={rightArrow}
            alt='rightArrow'
            draggable={false}/>
        </span>
      </CustomLink>
    ))
  )
  const Secondary = () => (
    props.secondary.map((el: any, idx: any) => (
      <CustomLink
        href={el.route}
        key={idx}
        onClick={() => { setIsOpened(null) }}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: el.color,
          textDecoration: 'none'
        }}
      >
        <span
          style={{ color: el.color }}>
          {el.value}
        </span>
        <span style={{ userSelect: 'none' }}>
          <img
            src={rightArrow}
            alt='rightArrow'
            draggable={false}/>
        </span>
      </CustomLink>
    ))
  )

  return (
    <>
      <ProfilePicture
        pictureSrc={'DEFAULT'}
        width='24px'
        setIsOpened={setIsOpened}
        inverse={props.inverse}
        name={props.firstname ?? 'firstname'}
        surname={props.lastname ?? 'lastname'}/>
      <div>
        <Menu
          disableScrollLock={true}
          id="account-menu"
          open={Boolean(isOpened)}
          onClose={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                width: '302px',
                padding: 1,
                overflowX: 'hidden',
                mt: 4,
                fontSize: '18px',
                marginTop: '40px',
                boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.20)'
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
          {[
            <div
              key="menu-content"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8vh'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  padding: '0 19px 0 19px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '14px',
                    paddingLeft: '6px'
                  }}
                >
                  <ProfilePicture
                    pictureSrc='DEFAULT'
                    width={'60'}
                    inverse={props.inverse}
                    name={props.firstname ?? 'John'}
                    surname={props.lastname ?? 'Smith'}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
                    <span style={{ fontSize: '18px' }}>{props.firstname ?? 'John'}</span>
                    <span style={{ fontSize: '18px' }}>{props.lastname ?? 'Smith'}</span>
                  </div>
                </div>
                <Line lineWidth='auto'/>
                <Main/>
                <Line lineWidth='auto'/>
                <Secondary/>
              </div>
              <div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '9px', padding: '0 19px 0 19px' }}>
                  <Line lineWidth='auto'/>
                  <ColoredText
                    singleText={'Sign out'}
                    onClick={() => {
                      void sessionApi.terminate({ uuid: AuthManager.getAccessTokenBody()?.sessionId })
                        .then(() => {
                          AuthManager.deleteTokens()
                          navigate(NavigationRoute.LOG_IN)
                        }).catch(() => {})
                    }}
                    cursor={'pointer'}
                  />
                </div>
              </div>
            </div>
          ]}
        </Menu>
      </div>
    </>
  )
}

export default ProfileMenu
