import React, { useEffect, useState } from 'react'
import ColoredText from '../components/text/ColoredText'
import { FontModeValues } from '../components/modes/FontMode'
import StandardButton from '../components/buttons/StandardButton'
import { ButtonModeValues } from '../components/modes/ButtonMode'
import { StandardSlideChoice } from '../components/SlideChoice/StandardSlideChoice'
import InterviewPrivacyRadioSelector from './InterviewPrivacyRadioSelector'
import Flipper from '../components/flipper/Flipper'
import giver from '../components/svg/resources/interview_searcher.svg'
import { COLORED_TEXT_VALUES } from '../util/Consts'
import { useDispatch, useSelector } from 'react-redux'
import { updateGiverFlipperValue } from '../features/GiverCalendarFlipperSlice'
import {
  addGiverInterviewType,
  addOrUpdateInterviewPrivacy,
  clearGiverInterviewData,
  getGiverInterview,
  getGiverInterviewTimesByDates,
  setGiverTimesByDateFromIsoStrings,
  updateGiverInterviewLanguages,
  updateGiverInterviewLevel
} from '../features/GiverInterviewSlice'
import toast from 'react-hot-toast'
import MarginWrapper from '../components/util/MarginWrapper'
import InterviewTypeImg from '../components/img/InterviewTypeImg'
import StandardModal from '../components/modal/StandardModal'
import { findObjectFromKeyWithoutUndefinedValue } from '../util/Helper'
import UnderlinedText from './UnderlinedText'
import GiverUpcomingCards from './GiverUpcomingCards'
import StandardTooltip from '../components/util/StandardTooltip'
import infoIcon from '../components/svg/resources/info.svg'
import InterviewStatus from './InterviewStatus'
import GiverRequestedCard from './GiverRequestedCard'
import GiverInterviewCalendar from '../components/calendar/GiverInterviewCalendar'
import GiverInterviewTimeRangeSelector from '../timeRangeSelector/GiverInterviewTimeRangeSelector'
import CircularLoading from '../components/CircularLoading'
import EnumValueMultipleAutocomplete from '../components/multipleAutocomplete/EnumValueMultipleAutocomplete'
import InterviewBalanceCards from '../components/payment/InterviewBalanceCards'
import GenericUnderlinedText from '../components/underlinedText/GenericUnderlinedText'
import InterviewFulfillCards from '../components/payment/InterviewFulfillCards'
import CardAttachmentForm from '../components/payment/CardAttachmentForm'
import AttachedCardList from '../components/payment/AttachedCardList'
import ApiManager from '../manager/ApiManager'
import {
  type BalanceDto,
  type GetInterviewDto,
  type GetInterviewTypeDto,
  type GetInterviewBalanceCardDto,
  InterviewBalanceControllerApi,
  InterviewControllerApi,
  type InterviewsWithBusinessDto,
  type InterviewBalanceAndCardDto,
  type EnumTypeDto,
  BankCardControllerApi, type GetBankCardDto,
  GetInterviewDtoLevelEnum as InterviewLevel, type BankCardCreationDto, GetInterviewDtoStatusEnum
} from '../api/ic'

export const enum SectionValue {
  UPCOMING = 1,
  NEW_REQUEST = 2,
  REQUESTED = 3
}

interface InterviewModalProps {
  obj: GetInterviewTypeDto
  isMainModalOpen: boolean
  setIsMainModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isOnLanguageLevelPrivacyPart: boolean
  setIsOnLanguageLevelPrivacyPart: React.Dispatch<React.SetStateAction<boolean>>
  isOnCalendarPart: boolean
  setIsOnCalendarPart: React.Dispatch<React.SetStateAction<boolean>>
  isOnFinalPart: boolean
  setIsOnFinalPart: React.Dispatch<React.SetStateAction<boolean>>
  isOnBalancePart: boolean
  setIsOnBalancePart: React.Dispatch<React.SetStateAction<boolean>>
  isOnCardAttachmentPart: boolean
  setIsOnCardAttachmentPart: React.Dispatch<React.SetStateAction<boolean>>
  isOnCardSelectionPart: boolean
  setIsOnCardSelectionPart: React.Dispatch<React.SetStateAction<boolean>>
  setTriggerInterviewRequest: React.Dispatch<React.SetStateAction<boolean>>
}

function InterviewModal (props: InterviewModalProps) {
  const dispatch = useDispatch()
  // PrivacyLevelLanguagePart States
  const [privacyValue, setPrivacyValue] = useState('')
  const [selectedLanguages, setSelectedLanguages] = useState<EnumTypeDto[]>([])
  const [availableLanguages, setAvailableLanguages] = useState<EnumTypeDto[]>([])
  // PrivacyLevelLanguagePart Error States
  const [languagesError, setLanguagesError] = useState<boolean>(false)
  const [slideChoiceError, setSlideChoiceError] = useState<boolean>(false)
  const [radioError, setRadioError] = useState<boolean>(false)
  // Button and Circular Loading States
  const [requestLoading, setRequestLoading] = useState(false)
  const [loading, setIsLoading] = useState(false)
  // Calendar and TimeRangeSelectorStates
  const [date, setDate] = useState<Date>(new Date())
  const timesByDates = useSelector(getGiverInterviewTimesByDates)
  const [busyTimes, setBusyTimes] = useState<string[] | undefined>(undefined)
  // Modal Section and Interview Information States
  const [isRequestedInterviewAvailable, setIsRequestedInterviewAvailable] = useState<boolean>(false)
  const [isOnEditPart, setIsOnEditPart] = useState<boolean>(false)
  const [triggerUpcomingAndCurrentInterviewDataFetching, setTriggerUpcomingAndCurrentInterviewDataFetching] = useState(false)
  const [sectionValue, setSectionValue] = useState<number>(SectionValue.NEW_REQUEST)
  const [upcomingInterviews, setUpcomingInterviews] = useState<GetInterviewDto[] | undefined>(undefined)
  const [hasOngoing, setHasOngoing] = useState<boolean>(false)
  const [interviewStatusString, setInterviewStatusString] = useState<null | string>(null)
  const [requestedInterview, setRequestedInterview] = useState<GetInterviewDto | undefined>(undefined)
  // Balance and Fulfill States (including selected ticket)
  const [attachedBankCards, setAttachedBankCards] = useState<GetBankCardDto[]>([])
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(0)
  const [balanceSectionValue, setBalanceSectionValue] = useState<string>('Balance')
  const [balanceCards, setBalanceCards] = useState<InterviewBalanceAndCardDto | undefined>()
  const [selectedTicket, setSelectedTicket] = useState<GetInterviewBalanceCardDto | BalanceDto | undefined>(undefined)
  const [bankCard, setBankCard] = useState<BankCardCreationDto | undefined>(undefined)
  const [selectedTicketType, setSelectedTicketType] = useState<'fulfill' | 'balance' | undefined>(undefined)
  const [maxAllowedCardCount, setMaxAllowedCardCount] = useState<number>(3)
  // Interview data
  const interviewObj = useSelector(getGiverInterview)
  const selectedLevel = interviewObj.level
  const interviewApi = ApiManager.getInstance(InterviewControllerApi)
  const interviewBalanceApi = ApiManager.getInstance(InterviewBalanceControllerApi)
  const bankCardApi = ApiManager.getInstance(BankCardControllerApi)

  const CreateOrUpdateInterview = async () => {
    const dates = timesByDates.flatMap((timesByDate) => {
      return timesByDate.times
    })
    if (!dates.length || (!selectedTicket?.balanceType && !isRequestedInterviewAvailable)) {
      toast.error('Please provide at least one date')
      setIsLoading(false)
      return false
    }
    return await interviewApi.createInterview({
      interviewCreationDto: {
        ...interviewObj,
        balanceType: selectedTicket?.balanceType ?? 'INTERVIEW_WITH_RECORDING', // TODO: fix
        requestedDatesByGiver: dates
      }
    }).then(() => {
      setIsLoading(false)
      props.setIsOnFinalPart(true)
      props.setIsOnCalendarPart(false)
      props.setIsOnBalancePart(false)
      props.setIsOnCardAttachmentPart(false)
      props.setIsOnCardSelectionPart(false)
      props.setIsOnLanguageLevelPrivacyPart(false)
      props.setTriggerInterviewRequest(true)
      return true
    }).catch(() => {
      return false
    })
  }

  useEffect(() => {
    if (triggerUpcomingAndCurrentInterviewDataFetching) {
      props.setTriggerInterviewRequest(true)
      props.setIsMainModalOpen(false)
      setTriggerUpcomingAndCurrentInterviewDataFetching(false)
    }
  }, [triggerUpcomingAndCurrentInterviewDataFetching])
  useEffect(() => {
    if (props.isOnBalancePart && !balanceCards) {
      setRequestLoading(true)
      void interviewBalanceApi.getAll()
        .then((res) => {
          setBalanceCards(res)
          if (res.interviewBalanceDtoes?.length) {
            setSelectedTicketType('balance')
            const isBalanceCardAvailable = res.interviewBalanceDtoes.filter((dto: BalanceDto) => dto.availableBalance).length > 0
            if (isBalanceCardAvailable) {
              setBalanceSectionValue('Balance')
            } else {
              setBalanceSectionValue('Fulfill')
            }
          } else if (res.interviewBalanceCardCreationDtoes?.length) {
            setSelectedTicketType('fulfill')
            setBalanceSectionValue('Fulfill')
          }
          setRequestLoading(false)
        }).catch(() => {
          setRequestLoading(false)
        })
    }
  }, [props.isOnBalancePart])

  useEffect(() => {
    if (props.isMainModalOpen) {
      // CLEAN DATA
      dispatch(clearGiverInterviewData())
      setPrivacyValue('')
      setSelectedLanguages([])
      props.setIsOnFinalPart(false)
      props.setIsOnCalendarPart(false)
      props.setIsOnBalancePart(false)
      props.setIsOnCardAttachmentPart(false)
      props.setIsOnCardSelectionPart(false)
      props.setIsOnLanguageLevelPrivacyPart(true)
      setSelectedCardIndex(0)
      setLanguagesError(false)
      setSlideChoiceError(false)
      setRadioError(false)
      setIsOnEditPart(false)
      setSectionValue(SectionValue.NEW_REQUEST)
      setBalanceCards(undefined)
      setBalanceSectionValue('Balance')
      dispatch(updateGiverFlipperValue(false))
      // DONE CLEANING DATA
      setRequestLoading(true)
      setIsLoading(true)
      void interviewApi.getInterviews({ type: props.obj.type })
        .then(res => {
          let interviewStatusString = null
          setAvailableLanguages(res.availableLanguages)
          setSelectedLanguages(res.preferredLanguages)
          if (res.busyTimestamps) {
            setBusyTimes([...res.busyTimestamps])
          }
          if (res.interview) {
            interviewStatusString = 'Requested'
            setSectionValue(SectionValue.REQUESTED)
            void setInterviewDTOParamsFromRequest(res).then(() => {
              setIsRequestedInterviewAvailable(true)
              setRequestedInterview(res.interview)
            }).catch(() => { setIsLoading(false) })
          } else {
            setInterviewStatusString(null)
            setSectionValue(SectionValue.NEW_REQUEST)
            setIsRequestedInterviewAvailable(false)
          }
          const upcomingResponse = res.upcomingInterviews
          if (upcomingResponse?.length) {
            setSectionValue(SectionValue.UPCOMING)
            setUpcomingInterviews(upcomingResponse)
            const isAtLeastOneOngoing = !!upcomingResponse?.find(i => i.status === GetInterviewDtoStatusEnum.Started)
            setHasOngoing(isAtLeastOneOngoing)
            interviewStatusString = isAtLeastOneOngoing ? 'Ongoing' : 'Upcoming'
          }
          setInterviewStatusString(interviewStatusString)
          setRequestLoading(false)
          setIsLoading(false)
        }).catch(() => {
          setRequestLoading(false)
          setIsLoading(false)
        })
    }
  }, [props.isMainModalOpen])
  const setInterviewDTOParamsFromRequest = async (data: InterviewsWithBusinessDto) => {
    if (data) {
      if (data.interview?.level) {
        dispatch(updateGiverInterviewLevel(data.interview.level))
      }
      if (data.interview.languages?.length) {
        setSelectedLanguages(data.interview.languages ? data.interview.languages.map(
          lang => findObjectFromKeyWithoutUndefinedValue(
            data.availableLanguages ?? [],
            { key: lang.valueOf() }
          )) : [])
        dispatch(updateGiverInterviewLanguages(Object.values(data.interview.languages)))
      }
      if (data.interview.isPublic !== undefined) {
        const privacy = data.interview.isPublic ? 'public' : 'private'
        dispatch(addOrUpdateInterviewPrivacy(data.interview.isPublic))
        setPrivacyValue(privacy)
      }
      dispatch(addGiverInterviewType(data.interview.type))
      if (data.interview?.requestedDatesByGiver) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        dispatch(setGiverTimesByDateFromIsoStrings(data.interview.requestedDatesByGiver))
      }
    }
  }
  const handlePageChange = async () => {
    if (props.isOnLanguageLevelPrivacyPart) {
      let error = false
      let errorMessage = 'Please Fill required fields\n '
      if (selectedLanguages.length === 0) {
        setLanguagesError(true)
        errorMessage = errorMessage + 'Interview Languages\n '
        error = true
      }
      if (!selectedLevel) {
        setSlideChoiceError(true)
        errorMessage = errorMessage + 'Interview Level\n '
        error = true
      }
      if (privacyValue === '') {
        setRadioError(true)
        errorMessage = errorMessage + 'Interview Privacy\n '
        error = true
      }
      if (error) {
        toast.error(errorMessage)
        return
      }
      const languageValues = selectedLanguages
        .map(language => language.key)
        .filter((key): key is string => key !== undefined)
      dispatch(updateGiverInterviewLanguages(languageValues))
      const isPublic = privacyValue === 'public'
      dispatch(addOrUpdateInterviewPrivacy(isPublic))
      dispatch(addGiverInterviewType(props.obj.type ?? ''))
      props.setIsOnCalendarPart(true)
      props.setIsOnBalancePart(false)
      props.setIsOnLanguageLevelPrivacyPart(false)
      props.setIsOnFinalPart(false)
    } else if (props.isOnCalendarPart) {
      const dates = timesByDates.flatMap((timesByDate) => {
        return timesByDate.times
      })
      if (dates.length === 0) {
        toast.error('Please provide at least one date')
        setIsLoading(false)
        return
      }
      if (!isRequestedInterviewAvailable) {
        props.setIsOnCalendarPart(false)
        props.setIsOnBalancePart(true)
      } else {
        const res = await CreateOrUpdateInterview().then()
        if (!res) {
          return
        }
        props.setIsOnCalendarPart(false)
        props.setIsOnFinalPart(true)
      }
    } else if (props.isOnBalancePart) {
      const isBalanceCardAvailable = balanceCards?.interviewBalanceDtoes
        ? balanceCards.interviewBalanceDtoes.filter((dto: BalanceDto) => dto.availableBalance).length > 0 : false
      if (!isBalanceCardAvailable &&
        balanceSectionValue === 'Balance') {
        toast.error('No Balance Cards Available. Buy from Fulfill section')
        return
      }
      if (selectedTicketType === 'fulfill') {
        setIsLoading(true)
        await bankCardApi.getAllUserBankCards().then(res => {
          if (res.cards.length) {
            setAttachedBankCards(res.cards)
            props.setIsOnBalancePart(false)
            props.setIsOnCardSelectionPart(true)
          } else {
            props.setIsOnBalancePart(false)
            props.setIsOnCardAttachmentPart(true)
          }
          setMaxAllowedCardCount(res.maxAllowedCardsNumber)
          setIsLoading(false)
        }).catch(() => { setIsLoading(false) })
      } else if (selectedTicketType === 'balance') {
        props.setIsOnBalancePart(false)
        props.setIsOnFinalPart(true)
      } else {
        toast.error('Please Select your Ticket')
      }

      const dates = timesByDates.flatMap((timesByDate) => {
        return timesByDate.times
      })
      if (!dates.length) {
        toast.error('Please provide at least one date')
        setIsLoading(false)
        return
      }
      if (selectedTicketType === 'balance') {
        await CreateOrUpdateInterview()
      }
    } else if (props.isOnCardAttachmentPart) {
      if (!selectedTicket?.id) {
        toast.error('Please select at least one card')
        setIsLoading(false)
        return
      }
      if (!bankCard) {
        return
      }
      await interviewBalanceApi.purchase(
        {
          interviewBalancePurchaseRequestDto: {
            bankCard: {
              num: bankCard.num,
              cvv: bankCard.cvv,
              expireDate: bankCard.expireDate,
              cardHolder: bankCard.cardHolder
            },
            balanceCardId: selectedTicket.id,
            rememberCard: !!bankCard._default
          }
        }
      ).then().catch(() => { toast.error('Something went wrong') })
      setIsLoading(true)
      const res = await CreateOrUpdateInterview()
      if (!res) {
        return
      }
      props.setIsOnCardAttachmentPart(false)
      props.setIsOnFinalPart(true)
    } else if (props.isOnCardSelectionPart) {
      setIsLoading(true)
      if (!selectedTicket?.id) {
        toast.error('Please select at least one card')
        setIsLoading(false)
        return
      }
      const card = attachedBankCards[selectedCardIndex]
      if (!card) {
        return
      }
      await interviewBalanceApi.purchaseByBankCardId({
        interviewBalancePurchaseWithBankCardIdRequestDto: {
          bankCardId: card.id,
          balanceCardId: selectedTicket.id
        }
      }).then().catch(() => { toast.error('Something went wrong') }) // TODO: make this depending on response
      const res = await CreateOrUpdateInterview()
      if (!res) {
        return
      }
      props.setIsOnCardSelectionPart(false)
      props.setIsOnFinalPart(true)
    }
  }

  const HandleInterviewPick = () => {
    props.setIsMainModalOpen(false)
  }

  const HandleModalClose = () => {
    props.setIsMainModalOpen(false)
    props.setIsOnFinalPart(false)
    props.setIsOnCalendarPart(false)
    props.setIsOnBalancePart(false)
    props.setIsOnCardAttachmentPart(false)
    props.setIsOnCardSelectionPart(false)
    props.setIsOnLanguageLevelPrivacyPart(true)
    dispatch(updateGiverFlipperValue(false))
  }
  const handleBalanceTypeSelection = (
    ticket: GetInterviewBalanceCardDto | BalanceDto | undefined,
    type: 'fulfill' | 'balance' | undefined
  ) => {
    setSelectedTicket(ticket)
    setSelectedTicketType(type)
  }
  return (
    <>
      <StandardModal isOpen={props.isMainModalOpen}
        disableUnderHeaderFixedComponent={props.isOnCardAttachmentPart}
        underHeaderFixedComponent={requestLoading
          ? <></>
          : <>
            {(props.isOnLanguageLevelPrivacyPart) ? <UnderlinedText
              isOnEditPart={isOnEditPart}
              setIsOnEditPart={setIsOnEditPart}
              isRequestAvailable={isRequestedInterviewAvailable}
              initialSection={sectionValue}
              setSectionValue={setSectionValue}
              firstWord={hasOngoing ? 'Ongoing + Upcomings' : 'Upcomings'}
              secondWord={isRequestedInterviewAvailable ? 'Requested' : 'New Request'}/>
              : <>
                {(props.isOnBalancePart && !requestLoading && !loading) &&
                  <div className={'d-flex flex-column gap-5'}>
                    <GenericUnderlinedText setSectionValue={setBalanceSectionValue} initialSection={balanceSectionValue}
                      firstWord={'Balance'} secondWord={'Fulfill'}/>
                    <div>
                      {balanceSectionValue === 'Fulfill' &&
                      <div className="d-flex flex-column justify-content-start align-items-center">
                        <InterviewFulfillCards
                          fulfillCards={balanceCards?.interviewBalanceCardCreationDtoes ?? []}
                          handleChange={handleBalanceTypeSelection}/>
                      </div>
                    }
                      {balanceSectionValue === 'Balance' &&
                      <div className="d-flex flex-column justify-content-start align-items-center">
                        <InterviewBalanceCards
                          balanceCards={balanceCards?.interviewBalanceDtoes?.length
                            ? balanceCards.interviewBalanceDtoes.filter(ticket => ticket.availableBalance)
                            : []}
                          handleChange={handleBalanceTypeSelection}/>
                      </div>
                    }
                    </div>
                  </div>
                }
                {
                  props.isOnCardSelectionPart && <AttachedCardList
                    attachedBankCards={attachedBankCards}
                    selectedCardIndex={selectedCardIndex}
                    maxAllowedCardsCount={maxAllowedCardCount}
                    selectable={true}
                    deletable={false}
                    setSelectedCardIndex={setSelectedCardIndex}
                    onAddClick={
                    () => {
                      props.setIsOnCardSelectionPart(false)
                      props.setIsOnCardAttachmentPart(true)
                    }
                    }
                  />
                }
              </>
              }
          </>}
        footerComponent={
          <>
            {
                sectionValue === SectionValue.UPCOMING
                  ? (
                    <ColoredText
                      cursor={requestLoading ? 'not-allowed' : 'pointer'}
                      pointerEvents={requestLoading ? 'none' : 'auto'}
                      fontSize={FontModeValues.DEFAULT}
                      singleText={'Cancel'}
                      onClick={HandleModalClose}
                      userSelect={'none'}/>
                    )
                  : sectionValue === SectionValue.REQUESTED ? (
                    <>
                      <ColoredText
                        cursor={requestLoading ? 'not-allowed' : 'pointer'}
                        pointerEvents={requestLoading ? 'none' : 'auto'}
                        fontSize={FontModeValues.DEFAULT}
                        singleText={'Cancel'}
                        onClick={HandleModalClose}
                        userSelect={'none'}/>
                    </>
                  ) : (
                    <>
                      {props.isOnLanguageLevelPrivacyPart && (
                      <>
                        <ColoredText
                          cursor={requestLoading ? 'not-allowed' : 'pointer'}
                          pointerEvents={requestLoading ? 'none' : 'auto'}
                          fontSize={FontModeValues.DEFAULT}
                          singleText={'Cancel'}
                          onClick={HandleModalClose}
                          userSelect={'none'}/>
                        <StandardButton
                          style={{ pointerEvents: requestLoading ? 'none' : 'auto' }}
                          onClick={handlePageChange}
                          text='Next'
                          clickable={!requestLoading}
                          sizeMode={ButtonModeValues.EXTRA_SMALL}
                          textFontSize={FontModeValues.SMALL}/>
                      </>)
                                   }
                      {(props.isOnCalendarPart) && (
                      <>
                        <span className={'d-flex align-items-center gap-4'}>
                          <StandardButton
                            onClick={() => {
                              props.setIsOnCalendarPart(false)
                              props.setIsOnLanguageLevelPrivacyPart(true)
                              dispatch(updateGiverFlipperValue(false))
                            }}
                            clickable={!requestLoading}
                            text='Back'
                            sizeMode={ButtonModeValues.EXTRA_SMALL}
                            textFontSize={FontModeValues.SMALL}/>
                        </span>
                        <span className={'d-flex align-items-center flex-row gap-4'}>
                          <ColoredText
                            singleText={'Cancel'}
                            fontSize={FontModeValues.DEFAULT}
                            onClick={HandleModalClose}
                            cursor={'pointer'}
                            userSelect={'none'}/>
                          <StandardButton
                            clickable={!requestLoading}
                            onClick={handlePageChange}
                            isLoading={loading}
                            text='Next'
                            sizeMode={ButtonModeValues.EXTRA_SMALL}
                            textFontSize={FontModeValues.SMALL}/>
                        </span>

                      </>)
                                   }
                      {(props.isOnBalancePart) && (
                      <>
                        <span className={'d-flex align-items-center gap-4'}>
                          <StandardButton
                            clickable={!requestLoading}
                            onClick={() => {
                              props.setIsOnBalancePart(false)
                              props.setIsOnCalendarPart(true)
                            }}
                            text='Back'
                            sizeMode={ButtonModeValues.EXTRA_SMALL}
                            textFontSize={FontModeValues.SMALL}/>
                        </span>
                        <span className={'d-flex align-items-center flex-row gap-4'}>
                          <ColoredText
                            singleText={'Cancel'}
                            fontSize={FontModeValues.DEFAULT}
                            onClick={HandleModalClose}
                            cursor={'pointer'}
                            userSelect={'none'}/>
                          <StandardButton
                            clickable={!requestLoading}
                            onClick={handlePageChange}
                            isLoading={loading}
                            text='Next'
                            sizeMode={ButtonModeValues.EXTRA_SMALL}
                            textFontSize={FontModeValues.SMALL}/>
                        </span>
                      </>)
                                   }
                      {(props.isOnCardAttachmentPart) && (
                      <>
                        <span className={'d-flex align-items-center gap-4'}>
                          <StandardButton
                            clickable={!requestLoading}
                            onClick={() => {
                              props.setIsOnCardAttachmentPart(false)
                              if (attachedBankCards.length) {
                                props.setIsOnCardSelectionPart(true)
                              } else {
                                props.setIsOnBalancePart(true)
                              }
                            }}
                            text='Back'
                            sizeMode={ButtonModeValues.EXTRA_SMALL}
                            textFontSize={FontModeValues.SMALL}/>
                        </span>
                        <span className={'d-flex align-items-center flex-row gap-4'}>
                          <ColoredText
                            singleText={'Cancel'}
                            fontSize={FontModeValues.DEFAULT}
                            onClick={HandleModalClose}
                            cursor={'pointer'}
                            userSelect={'none'}/>
                          <StandardButton
                            clickable={!requestLoading}
                            onClick={handlePageChange}
                            isLoading={loading}
                            text='Next'
                            sizeMode={ButtonModeValues.EXTRA_SMALL}
                            textFontSize={FontModeValues.SMALL}/>
                        </span>
                      </>)
                                   }
                      {(props.isOnCardSelectionPart) && (
                      <>
                        <span className={'d-flex align-items-center gap-4'}>
                          <StandardButton
                            clickable={!requestLoading}
                            onClick={() => {
                              props.setIsOnCardSelectionPart(false)
                              props.setIsOnBalancePart(true)
                            }}
                            text='Back'
                            sizeMode={ButtonModeValues.EXTRA_SMALL}
                            textFontSize={FontModeValues.SMALL}/>
                        </span>
                        <span className={'d-flex align-items-center flex-row gap-4'}>
                          <ColoredText
                            singleText={'Cancel'}
                            fontSize={FontModeValues.DEFAULT}
                            onClick={HandleModalClose}
                            cursor={'pointer'}
                            userSelect={'none'}/>
                          <StandardButton
                            clickable={!requestLoading}
                            onClick={handlePageChange}
                            isLoading={loading}
                            text='Next'
                            sizeMode={ButtonModeValues.EXTRA_SMALL}
                            textFontSize={FontModeValues.SMALL}/>
                        </span>
                      </>)
                                   }
                      {props.isOnFinalPart && (
                      <>
                        <div></div>
                        <span className={'d-flex justify-content-end align-items-center'}>
                          <StandardButton
                            clickable={!requestLoading}
                            text={'Great'}
                            onClick={HandleInterviewPick}
                            sizeMode={ButtonModeValues.EXTRA_SMALL}
                            textFontSize={FontModeValues.SMALL}/>
                        </span>
                      </>)
                    }
                    </>
                  )
                }
          </>
        }
        width={`${(screen.availWidth * 40 / 100)}px`}
        height={`${(screen.availHeight * 70 / 100)}px`}
        headerComponent={
          <MarginWrapper top={'5px'} bottom={'5px'}>
            <div className={'d-flex flex-row align-items-center gap-1'}>
              <InterviewTypeImg obj={props.obj} mini={true}/>
              <ColoredText
                fontSize={'32px'}
                fontWeight={300}
                lineHeight={1}
                singleText={props.obj.name ?? ''}/>
              {interviewStatusString && (
              <InterviewStatus
                type={'modal'}
                interviewStatusString={interviewStatusString}/>)}
              {interviewStatusString && (<StandardTooltip texts={COLORED_TEXT_VALUES.PRIVATE_PUBLIC}>
                <img
                  src={infoIcon}
                  alt={'Info'}
                  width={'22px'}
                  height={'22px'}/>
              </StandardTooltip>)}
            </div>
          </MarginWrapper>}>
        {/*  BODY  */}
        <div style={{ position: 'relative' }}>
          {
            (requestedInterview && requestLoading) || loading ? (<CircularLoading/>)
              : (
                <>
                  {
                    sectionValue === SectionValue.UPCOMING ? (
                      <GiverUpcomingCards
                        setIsMainModalOpen={props.setIsMainModalOpen}
                        upcomingInterviews={upcomingInterviews ?? []}
                        setTriggerUpcomingAndCurrentInterviewDataFetching={setTriggerUpcomingAndCurrentInterviewDataFetching}/>
                    ) : (
                      sectionValue === SectionValue.REQUESTED
                        ? (
                          <>
                            {requestedInterview && <GiverRequestedCard
                              setIsMainModalOpen={props.setIsMainModalOpen}
                              setIsOnEditPart={setIsOnEditPart}
                              interview={requestedInterview}
                              setTriggerUpcomingAndCurrentInterviewDataFetching={setTriggerUpcomingAndCurrentInterviewDataFetching}
                              setSectionValue={setSectionValue}/>}
                          </>)
                        : (
                          <>
                            {props.isOnLanguageLevelPrivacyPart && (
                              <div className={'d-flex flex-column justify-content-center gap-5'}>
                                <span>
                                  <EnumValueMultipleAutocomplete
                                    key={'languages-autocomplete'}
                                    label={'Languages'}
                                    error={languagesError}
                                    selectedValues={selectedLanguages}
                                    setSelectedValue={setSelectedLanguages}
                                    width={'100%'}
                                    isRequired={true}
                                    onChange={() => {
                                      setLanguagesError(false)
                                    }}
                                    items={availableLanguages}/>
                                </span>
                                <span>
                                  <StandardSlideChoice
                                    isRequired={true}
                                    label="Level"
                                    singleChoice={true}
                                    error={slideChoiceError}
                                    onChange={() => { setSlideChoiceError(false) }}
                                    values={[
                                      { value: InterviewLevel.Beginner, isChosen: selectedLevel === InterviewLevel.Beginner },
                                      { value: InterviewLevel.Intermediate, isChosen: selectedLevel === InterviewLevel.Intermediate },
                                      { value: InterviewLevel.Pro, isChosen: selectedLevel === InterviewLevel.Pro }
                                    ]} dispatchSelection={updateGiverInterviewLevel}/>
                                </span>
                                <span>
                                  <InterviewPrivacyRadioSelector
                                    isRequired={true}
                                    error={radioError}
                                    onChange={() => { setRadioError(false) }}
                                    setPrivacyValue={setPrivacyValue}
                                    privacyValue={privacyValue}/>
                                </span>
                              </div>
                            )}

                            {props.isOnCalendarPart && (
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <Flipper width="350px" height="350px"
                                  frontComponent={<GiverInterviewCalendar setDate={setDate}/>}
                                  backComponent={<GiverInterviewTimeRangeSelector busyTimeStamps={busyTimes}
                                    date={date}
                                    initialTime={9}
                                    delayBetweenTimeBlocks={30}
                                    interviewDuration={60}/>}/>
                              </div>
                            )}
                            {props.isOnCardAttachmentPart && <CardAttachmentForm setBankCard={setBankCard}/>}
                            {props.isOnFinalPart && (
                              <div
                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', gap: '30px' }}>
                                <img alt="interview" src={giver}
                                  width="45%" height="45%"
                                  draggable={false} style={{ userSelect: 'none' }}/>
                                <ColoredText texts={COLORED_TEXT_VALUES.INTERVIEW_SEARCHER_TEXT} centered={true}
                                  fontSize={FontModeValues.SMALL}/>
                              </div>
                            )}
                          </>
                          ))}
                </>
                )
          }
        </div>
      </StandardModal>
    </>

  )
}

export default InterviewModal
