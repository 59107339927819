/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetInterviewTypeDto,
  InterviewTypeCreationDto,
  InterviewTypeUpdateDto,
} from '../models/index';
import {
    GetInterviewTypeDtoFromJSON,
    GetInterviewTypeDtoToJSON,
    InterviewTypeCreationDtoFromJSON,
    InterviewTypeCreationDtoToJSON,
    InterviewTypeUpdateDtoFromJSON,
    InterviewTypeUpdateDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface ChangeAvailabilityRequest {
    id: string;
    availability: boolean;
}

export interface CreateInterviewTypeRequest {
    interviewTypeCreationDto: InterviewTypeCreationDto;
}

export interface DeleteInterviewTypeRequest {
    id: string;
}

export interface GetInterviewTypeRequest {
    id?: string;
    type?: string;
}

export interface UpdateInterviewTypeRequest {
    interviewTypeUpdateDto: InterviewTypeUpdateDto;
}

/**
 * 
 */
export class InterviewTypeControllerApi extends runtime.BaseAPI {

    /**
     * Changes availability of interview type
     * Change availability of interview type
     */
    async changeAvailabilityRaw(requestParameters: ChangeAvailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewTypeDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling changeAvailability().'
            );
        }

        if (requestParameters['availability'] == null) {
            throw new runtime.RequiredError(
                'availability',
                'Required parameter "availability" was null or undefined when calling changeAvailability().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['availability'] != null) {
            queryParameters['availability'] = requestParameters['availability'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interviewType/changeAvailability`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewTypeDtoFromJSON(jsonValue));
    }

    /**
     * Changes availability of interview type
     * Change availability of interview type
     */
    async changeAvailability(requestParameters: ChangeAvailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewTypeDto> {
        const response = await this.changeAvailabilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates an interview type
     * Create interview type
     */
    async createInterviewTypeRaw(requestParameters: CreateInterviewTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewTypeDto>> {
        if (requestParameters['interviewTypeCreationDto'] == null) {
            throw new runtime.RequiredError(
                'interviewTypeCreationDto',
                'Required parameter "interviewTypeCreationDto" was null or undefined when calling createInterviewType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interviewType/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewTypeCreationDtoToJSON(requestParameters['interviewTypeCreationDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewTypeDtoFromJSON(jsonValue));
    }

    /**
     * Creates an interview type
     * Create interview type
     */
    async createInterviewType(requestParameters: CreateInterviewTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewTypeDto> {
        const response = await this.createInterviewTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an interview type
     * Delete interview type
     */
    async deleteInterviewTypeRaw(requestParameters: DeleteInterviewTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewTypeDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteInterviewType().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interviewType/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewTypeDtoFromJSON(jsonValue));
    }

    /**
     * Deletes an interview type
     * Delete interview type
     */
    async deleteInterviewType(requestParameters: DeleteInterviewTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewTypeDto> {
        const response = await this.deleteInterviewTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all available interview types
     * Get all available interview types
     */
    async getAllAvailableRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetInterviewTypeDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interviewType/getAllAvailable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInterviewTypeDtoFromJSON));
    }

    /**
     * Gets all available interview types
     * Get all available interview types
     */
    async getAllAvailable(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetInterviewTypeDto>> {
        const response = await this.getAllAvailableRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets all available interview types for user
     * Get all available interview types per user
     */
    async getAllAvailablePerUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetInterviewTypeDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interviewType/v2/getAllAvailable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInterviewTypeDtoFromJSON));
    }

    /**
     * Gets all available interview types for user
     * Get all available interview types per user
     */
    async getAllAvailablePerUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetInterviewTypeDto>> {
        const response = await this.getAllAvailablePerUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets all interview types
     * Get all interview types
     */
    async getAllInterviewTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetInterviewTypeDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interviewType/getAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInterviewTypeDtoFromJSON));
    }

    /**
     * Gets all interview types
     * Get all interview types
     */
    async getAllInterviewTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetInterviewTypeDto>> {
        const response = await this.getAllInterviewTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets an interview type
     * Get interview type
     */
    async getInterviewTypeRaw(requestParameters: GetInterviewTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewTypeDto>> {
        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interviewType/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewTypeDtoFromJSON(jsonValue));
    }

    /**
     * Gets an interview type
     * Get interview type
     */
    async getInterviewType(requestParameters: GetInterviewTypeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewTypeDto> {
        const response = await this.getInterviewTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an interview type
     * Update interview type
     */
    async updateInterviewTypeRaw(requestParameters: UpdateInterviewTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewTypeDto>> {
        if (requestParameters['interviewTypeUpdateDto'] == null) {
            throw new runtime.RequiredError(
                'interviewTypeUpdateDto',
                'Required parameter "interviewTypeUpdateDto" was null or undefined when calling updateInterviewType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interviewType/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewTypeUpdateDtoToJSON(requestParameters['interviewTypeUpdateDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewTypeDtoFromJSON(jsonValue));
    }

    /**
     * Updates an interview type
     * Update interview type
     */
    async updateInterviewType(requestParameters: UpdateInterviewTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewTypeDto> {
        const response = await this.updateInterviewTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
