import React, { useEffect, useState } from 'react'
import SmallDropdown from '../components/dropdown/SmallDropdown'
import StandardInput from '../components/input/StandardInput'
import { InputModeValues } from '../components/modes/InputModes'
import * as XLSX from 'xlsx'
import StandardButton from '../components/buttons/StandardButton'
import { ButtonModeValues } from '../components/modes/ButtonMode'
import MarginWrapper from '../components/util/MarginWrapper'

export default function GMD () {
  const [selectedImage, setSelectedImage] = useState<File | null>(null)

  const [tesakaniList, setTesakaniList] = useState<any[]>([])
  const [shertkaniList, setShertList] = useState<any[]>([])
  const [buyrList, setBuyrList] = useState<any[]>([])
  const [erkarutyunList, setErkarutyunList] = useState<any[]>([])
  const [blockList, setBlockList] = useState<any[]>([])
  const [ginList, setGinList] = useState<any[]>([])

  const [firm, setFirm] = useState<string | undefined>(undefined)
  const [firmList, setFirmList] = useState<any[]>([])
  const [firmCode, setFirmCode] = useState<string | undefined>(undefined)

  const [tesak, setTesak] = useState(undefined)
  const [shert, setShert] = useState(undefined)
  const [buyr, setBuyr] = useState(undefined)
  const [erkarutyun, setErkarutyun] = useState(undefined)
  const [block, setBlock] = useState(undefined)
  const [gin, setGin] = useState(undefined)

  const [excelJsonData, setExcelJsonData] = useState<any[] | undefined>(undefined)
  const [filtered, setFiltered] = useState<any[] | undefined>(undefined)

  useEffect(() => {
    if (!selectedImage) {
      return
    }

    const reader = new FileReader()

    reader.onload = async (event) => {
      const arrayBuffer = event.target?.result
      if (arrayBuffer) {
        try {
          const workbook = XLSX.read(arrayBuffer, { type: 'array' })

          const worksheet = workbook.Sheets['Գնացուցակ']
          if (worksheet) {
            const jsonData = XLSX.utils.sheet_to_json(worksheet)
            setExcelJsonData(jsonData)
            setFiltered(jsonData)
          }

          const firmExcel = workbook.Sheets['Ֆիրմա']
          if (firmExcel) {
            const jsonData: any[] = XLSX.utils.sheet_to_json(firmExcel)
            setFirmList(jsonData.map((el) => el['Անվանում']))
          }
        } catch (error) {
          console.error('Error processing Excel file', error)
        }
      }
    }

    reader.onerror = (error) => {
      console.error('Error reading file', error)
    }

    reader.readAsArrayBuffer(selectedImage)
  }, [selectedImage])

  useEffect(() => {
    if (excelJsonData) {
      const uniqueTesakaniList = Array.from(new Set(excelJsonData.map(elem => elem['Անվանում'])))
      setTesakaniList(uniqueTesakaniList)

      const uniqueShertList = Array.from(new Set(excelJsonData.map(elem => elem['Շերտ'])))
      setShertList(uniqueShertList)

      const buyrUniqueList = Array.from(new Set(excelJsonData.map(elem => elem['Բույր'])))
      setBuyrList(buyrUniqueList)

      const erkarutyunList = Array.from(new Set(excelJsonData.map(elem => elem['Երկարություն (մ)'])))
      setErkarutyunList(erkarutyunList)

      const blockList = Array.from(new Set(excelJsonData.map(elem => elem['Բլոկ'])))
      setBlockList(blockList)

      const ginList = Array.from(new Set(excelJsonData.map(elem => elem['Գին (դրամ)'])))
      setGinList(ginList)
    }
  }, [excelJsonData])

  useEffect(() => {
    if (excelJsonData) {
      const filtered = excelJsonData.filter(el => {
        if (tesak && el['Անվանում'] !== tesak) {
          return false
        } else if (shert && el['Շերտ'] !== +shert) {
          return false
        } else if (buyr && el['Բույր'] !== buyr) {
          return false
        } else if (erkarutyun && el['Երկարություն (մ)'] !== +erkarutyun) {
          return false
        } else if (block && el['Բլոկ'] !== +block) {
          return false
        } else if (gin && el['Գին (դրամ)'] !== +gin) {
          return false
        }
        return true
      })
      setFiltered(filtered)
    }
  }, [tesak,
    shert,
    buyr,
    erkarutyun,
    block,
    gin
  ])

  useEffect(() => {
    if (firm) {
      setFirmCode((firmList.indexOf(firm) + 1) + '')
    } else {
      setFirmCode('N/A')
    }
  }, [firm])

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files ? e?.target?.files[0] : null
    setSelectedImage(file)
  }

  return (
    <div className={'d-flex flex-column m-5 p-5 gap-3'}>
      <input type="file" onChange={handleImageChange} />
      <div className={'d-flex flex-row gap-3'}>
        <SmallDropdown
          sizeMode={InputModeValues.LARGE}
          label={'Firma'}
          items={firmList}
          selected={firm}
          borderRadius={'5px'}
          setIsSelected={setFirm}/>
        <StandardInput
          disabled={true}
          setInputValue={setFirmCode}
          inputValue={firmCode}
          placeHolder={'Code'}
          label={'Firm Code (auto)'}/>
      </div>
      <div className={'d-flex flex-row align-items-center gap-3'} >
        <SmallDropdown
          sizeMode={InputModeValues.LARGE}
          label={'Tesak'}
          items={tesakaniList}
          selected={tesak}
          borderRadius={'5px'}
          setIsSelected={setTesak}/>
        <MarginWrapper top={'20px'}>
          <StandardButton
            text={'Clear'}
            sizeMode={ButtonModeValues.EXTRA_SMALL}
            onClick={() => {
              setTesak(undefined)
            }} />
        </MarginWrapper>
      </div>
      <div className={'d-flex flex-row align-items-center gap-3'} >
        <SmallDropdown
          sizeMode={InputModeValues.LARGE}
          setIsSelected={setShert}
          selected={shert}
          label={'Shert'}
          borderRadius={'5px'}
          items={shertkaniList}/>
        <MarginWrapper top={'20px'}>
          <StandardButton
            text={'Clear'}
            sizeMode={ButtonModeValues.EXTRA_SMALL}
            onClick={() => {
              setShert(undefined)
            }} />
        </MarginWrapper>
      </div>
      <div className={'d-flex flex-row align-items-center gap-3'} >
        <SmallDropdown
          sizeMode={InputModeValues.LARGE}
          label={'Buyr'}
          items={buyrList}
          selected={buyr}
          borderRadius={'5px'}
          setIsSelected={setBuyr}/>
        <MarginWrapper top={'20px'}>
          <StandardButton
            text={'Clear'}
            sizeMode={ButtonModeValues.EXTRA_SMALL}
            onClick={() => {
              setBuyr(undefined)
            }} />
        </MarginWrapper>
      </div>
      <div className={'d-flex flex-row align-items-center gap-3'} >
        <SmallDropdown
          sizeMode={InputModeValues.LARGE}
          label={'Erkarutyun (m)'}
          items={erkarutyunList}
          selected={erkarutyun}
          borderRadius={'5px'}
          setIsSelected={setErkarutyun}/>
        <MarginWrapper top={'20px'}>
          <StandardButton
            text={'Clear'}
            sizeMode={ButtonModeValues.EXTRA_SMALL}
            onClick={() => {
              setErkarutyun(undefined)
            }} />
        </MarginWrapper>
      </div>
      <div className={'d-flex flex-row align-items-center gap-3'} >
        <SmallDropdown
          sizeMode={InputModeValues.LARGE}
          borderRadius={'5px'}
          label={'Block (qanak)'}
          selected={block}
          setIsSelected={setBlock}
          items={blockList}/>
        <MarginWrapper top={'20px'}>
          <StandardButton
            text={'Clear'}
            sizeMode={ButtonModeValues.EXTRA_SMALL}
            onClick={() => {
              setBlock(undefined)
            }} />
        </MarginWrapper>
      </div>
      <div className={'d-flex flex-row align-items-center gap-3'} >
        <SmallDropdown
          sizeMode={InputModeValues.LARGE}
          borderRadius={'5px'}
          selected={gin}
          setIsSelected={setGin}
          items={ginList}
          label={'Gin (dramov)'}/>
        <MarginWrapper top={'20px'}>
          <StandardButton
            text={'Clear'}
            sizeMode={ButtonModeValues.EXTRA_SMALL}
            onClick={() => {
              setGin(undefined)
            }} />
        </MarginWrapper>
      </div>
      {filtered && <div>
        <h2>Static Excel Data</h2>
        <pre>{JSON.stringify(filtered, null, 2)}</pre>
      </div>}
      {/* [X] Firma ['asd'] */}
      {/* [X] Tesakananin ['asd'] */}
      {/*  Shert [1, 2, 3, 4, 5] */}
      {/*  Buyr [''] */}
      {/*  Erkarutyun metrov [] */}
      {/*  Block  */}
      {/*  Gin  */}
    </div>
  )
}
