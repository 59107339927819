import React, { useEffect, useState } from 'react'
import Logo from '../svg/Logo'
import { type NavigationRoute } from '../../enumeration/NavigationRoute'
import { NAVIGATION_MODES, NavigationModeValues } from '../modes/NavigationMode'
import CircleButtonWithBorder from '../buttons/CircleButtonWithBorder'
import NavigationItemText from '../text/NavigationItemText'
import { ColorMode } from '../modes/ColorMode'
import ColoredText from '../text/ColoredText'
import { type NavigationItemType } from '../../enumeration/NavigationItemType'
import NavigationProfile from './NavigationProfile'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'

export interface NavigationItemActionable {
  enumValue: NavigationItemType | string
  link?: NavigationRoute
}

interface NavigationNewProps {
  navLeft?: NavigationItemActionable[]
  navigationMode: NavigationModeValues
  mobileNavigationMode?: NavigationModeValues
  borderButtonText?: string
  navItems?: NavigationItemActionable[]
  mobileNavItems?: NavigationItemActionable[]
  borderButtonRoute?: NavigationRoute
  borderButtonMode?: ColorMode
  mobileBorderButtonMode?: ColorMode
  height?: string
  interviewText?: string
}

function Navigation (props: NavigationNewProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [scrolled, setScrolled] = useState(false)
  const navMode = NAVIGATION_MODES[isMobile ? props.mobileNavigationMode ?? props.navigationMode : props.navigationMode]
  const navModeConfigs = !scrolled ? isMobile ? navMode.mobile : navMode.browser : navMode.scrolled
  const navItems = isMobile && props.mobileNavItems ? props.mobileNavItems : props.navItems
  const navItemsMode = navModeConfigs.navItemsMode ?? ColorMode.DARK
  const borderButtonMode = !scrolled ? isMobile ? props.mobileBorderButtonMode : props.borderButtonMode : navModeConfigs.borderButtonMode

  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        if (window.scrollY > 20) {
          setScrolled(true)
        } else {
          setScrolled(false)
        }
      })
  })

  return (
    <div
      style={{
        position: 'sticky',
        height: props.height ?? '100px',
        zIndex: 3
      }}>
      <div
        className={'container-fluid'}
        style={{
          paddingLeft: '1vh',
          paddingRight: '6vh',
          paddingTop: '1.5vh',
          paddingBottom: '1.5vh',
          position: navModeConfigs.position ?? 'static',
          backgroundColor: navModeConfigs.backgroundColor,
          boxShadow: navModeConfigs.boxShadow ?? 'none'
        }}>
        <div style={{
          padding: props.mobileNavigationMode === NavigationModeValues.FLOATING_WITH_BORDERED_BUTTON_REVERSE && isMobile ? '7px 0 7px 0' : ''
        }}
          className={`d-flex flex-row align-items-center justify-content-between ${
              (props.navigationMode === NavigationModeValues.FIXED_MAIN || props.navigationMode === NavigationModeValues.FIXED_MAIN_WITH_PLAN_AND_PROFILE || (props.mobileNavigationMode === NavigationModeValues.FLOATING_WITH_BORDERED_BUTTON_REVERSE && isMobile)) || (scrolled && props.mobileNavigationMode === NavigationModeValues.FIXED_WHITE_WITH_INTERVIEW_INFO) ? '' : 'mt-1'}`}>
          <div className={'container d-flex flex-row align-items-center'}>
            <Logo type={navModeConfigs.logoMode}/>
            {navItems?.map((e, index) =>
              <NavigationItemText
                key={index}
                link={e.link}
                text={e.enumValue}
                colorMode={navItemsMode}/>
            )}
          </div>
          {
            navModeConfigs.borderButton
              ? <CircleButtonWithBorder
                  text={props.borderButtonText}
                  route={props.borderButtonRoute}
                  colorMode={borderButtonMode}/>
              : navModeConfigs.navigationProfile
                ? <NavigationProfile/>
                : navModeConfigs.interviewText && <ColoredText
                    whiteSpace='nowrap'
                    color = {navModeConfigs.interviewTextMode === ColorMode.LIGHT ? 'white' : 'black'}
                    singleText= {props.interviewText}/>
          }
        </div>
      </div>
    </div>
  )
}

export default Navigation
