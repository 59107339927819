import React, { useEffect, useState } from 'react'
import TakerInterviewCard from './TakerInterviewCard'
import { Skeleton } from '@mui/material'
import UnderlinedText from '../../giver/UnderlinedText'
import { SectionValue } from '../../giver/InterviewModal'
import TakerUpcomingList from './TakerUpcomingList'
import ColoredText from '../text/ColoredText'
import ApiManager from '../../manager/ApiManager'
import { type GetInterviewDto, GetInterviewDtoStatusEnum, type TakerAvailableInterviewDatesDto, TakerControllerApi } from '../../api/ic'

function TakerInterviewCards () {
  const [cards, setCards] = useState<TakerAvailableInterviewDatesDto[]>([])
  const [updateTimes, setUpdateTimes] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [sectionValue, setSectionValue] = useState(SectionValue.NEW_REQUEST)
  const [upcomingList, setUpcomingList] = useState<GetInterviewDto[]>([])
  const [hasOngoing, setHasOngoing] = useState<boolean>(false)

  const takerApi = ApiManager.getInstance(TakerControllerApi)

  useEffect(() => {
    setIsLoading(true)
    void takerApi.getTakerInterviewsInfo()
      .then(res => {
        const upcomingResponse = res.upcomingInterviews
        if (upcomingResponse?.length) {
          upcomingResponse.sort((a, b) => {
            if (a.acceptedDate && b.acceptedDate) {
              if (a.acceptedDate > b.acceptedDate) {
                return 1
              } else if (a.acceptedDate < b.acceptedDate) {
                return -1
              }
            }
            return 0
          })
          setUpcomingList(upcomingResponse)
          const isAtLeastOneOngoing = !!upcomingResponse?.find(i => i.status === GetInterviewDtoStatusEnum.Started)
          setHasOngoing(isAtLeastOneOngoing)
          setSectionValue(SectionValue.UPCOMING)
        } else {
          setUpcomingList([])
          setSectionValue(SectionValue.NEW_REQUEST)
        }
        if (res.availableInterviewDates) {
          const interviewDates = [...res.availableInterviewDates]
          interviewDates.map(obj => {
            return {
              type: obj.type,
              dates: obj.dates // TODO check this check
            }
          }
          )
          setCards(res.availableInterviewDates)
          setIsLoading(false)
        }
      }).catch(() => {
        setIsLoading(false)
      })

    setUpdateTimes(false)
  }, [updateTimes])
  return (
    <div className={'d-flex flex-column'} style={{ width: '100%' }}>
      {isLoading && <div className={'mt-4 container-md d-flex flex-row pb-5 gap-3'} style={{ width: '90%', paddingLeft: '30px' }}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={26}
          width={120}
          sx={{ borderRadius: '10px' }}/>
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={26}
          width={120}
          sx={{ borderRadius: '10px' }}/>
      </div>}
      {!isLoading && <span className={'mt-4 container-md'} style={{ width: '90%', paddingLeft: '30px' }}>
        <UnderlinedText initialSection={sectionValue} setSectionValue={setSectionValue}
          firstWord={hasOngoing ? 'Ongoings + Upcomings' : 'Upcomings'} secondWord={'Take New One'}
          type={'taker'}/>
      </span>}
      <div
        className={'d-flex flex-row flex-wrap align-items-center justify-content-evenly'}
        style={{
          gap: '20px',
          padding: sectionValue === SectionValue.NEW_REQUEST ? '30px 0px 20px 0px' : '0px 0px 20px 0px'
        }}>
        {isLoading && (
          Array.from({ length: 2 }).map((_, index) => (
            <Skeleton
              key={index}
              animation="wave"
              variant="rectangular"
              height={400}
              width={350}
              sx={{ borderRadius: '10px' }}/>
          ))
        )}
        {!isLoading &&
          (
            <>
              {sectionValue === SectionValue.NEW_REQUEST && (
                cards.map((el: TakerAvailableInterviewDatesDto, idx: number) => (
                  <TakerInterviewCard
                    key={idx}
                    setUpdateTimes={setUpdateTimes}
                    obj={el}/>
                ))
              )}
              {sectionValue === SectionValue.UPCOMING && (
                <>
                  {upcomingList.length
                    ? <TakerUpcomingList upcomingInterviews={upcomingList} setUpdateTimes={setUpdateTimes}/>
                    : <div className={'d-flex justify-content-center align-items-center mt-5'}>
                      <ColoredText singleText={'No Upcoming Interviews. Please check Take New One section'}/>
                    </div>
                  }
                </>
              )}
            </>
          )}
      </div>
    </div>
  )
}

export default TakerInterviewCards
