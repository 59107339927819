import React from 'react'
import LineTextLine from '../components/util/LineTextLine'
import ColoredText from '../components/text/ColoredText'
import { COLORED_TEXT_VALUES, NAVIGATION_ITEMS } from '../util/Consts'
import Background from '../components/svg/Background'
import adPageBackground from '../components/svg/resources/ad_page_background.svg'
import EmailRoleInput from '../components/AdPage/EmailRoleInput'
import interviewers from '../components/svg/resources/interviewers.svg'
import purpleCircles from '../components/svg/resources/purple_circles.svg'
import SVGHandler from '../components/svg/SVGHandler'
import Navigation from '../components/navigation/Navigation'
import { NavigationModeValues } from '../components/modes/NavigationMode'
import MarginWrapper from '../components/util/MarginWrapper'
import { FontModeValues } from '../components/modes/FontMode'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../features/DeviceTypeInformationSlice'
import { ColorMode } from '../components/modes/ColorMode'

export default function AdPage () {
  const deviceType = useSelector(GetDeviceTypeInformation)
  const isMobile = deviceType.isMobile
  const isLandscape = deviceType.isLandscape
  return (
    <>
      <Navigation
        navigationMode={NavigationModeValues.FLOATING}
        mobileNavigationMode={NavigationModeValues.FIXED_MAIN}
        navItems={[NAVIGATION_ITEMS.ABOUT, NAVIGATION_ITEMS.CONTACT_US]}
        mobileBorderButtonMode={ColorMode.DARK}
        borderButtonMode={ColorMode.DARK}/>
      <Background
        svg={adPageBackground}
        rate={0.91}
        style={{ overflow: 'hidden', width: '100%', minHeight: '100%', position: 'fixed' }}/>
      <div
        className={`${(isMobile && !isLandscape) ? 'container-fluid' : 'container'} d-flex flex-column align-items-center`}
        style={{ position: (isMobile && isLandscape) ? 'relative' : 'static', gap: '10px' }}>
        <MarginWrapper
          top={isMobile ? '10px' : ''}>
          <ColoredText
            centered={true}
            fontWeight={700}
            lineHeight={1.4}
            fontSize={isMobile ? '32px' : '54px'}
            texts={COLORED_TEXT_VALUES.WELCOME_TEXT}/>
        </MarginWrapper>
        <LineTextLine
          text={'OR'}
          lineWidth={'23vw'}/>
        <ColoredText
          centered={true}
          fontSize={FontModeValues.EXTRA_LARGE}
          texts={COLORED_TEXT_VALUES.HR_RECRUITER_DISCOVER}/>
        <MarginWrapper
          top={'2.5vw'}>
          <EmailRoleInput/>
        </MarginWrapper>
      </div>
      {!isMobile && (
      <SVGHandler
        svg={interviewers}
        currentY = {'350px'}
        position={'fixed'}
        inner_style={{
          transform: 'scaleX(-1)',
          zIndex: '-1'
        }}/>
      )}
      {(isMobile && !isLandscape) && <>
        <SVGHandler
          svg={purpleCircles}
          currentX= '1%'
          currentY={'100px'}
          inner_style={{
            width: '100px',
            zIndex: '-1'
          }}/>
      </>}
      {(isMobile && isLandscape) &&
      <SVGHandler
        svg={interviewers}
        currentX='20%'
        currentY={'100px'}
        inner_style={{
          width: '100px',
          transform: 'scaleX(-1)'
        }}/>
      }
    </>
  )
}
