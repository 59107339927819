/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ICURLDto } from './ICURLDto';
import {
    ICURLDtoFromJSON,
    ICURLDtoFromJSONTyped,
    ICURLDtoToJSON,
} from './ICURLDto';
import type { PersonalInformationResponseDto } from './PersonalInformationResponseDto';
import {
    PersonalInformationResponseDtoFromJSON,
    PersonalInformationResponseDtoFromJSONTyped,
    PersonalInformationResponseDtoToJSON,
} from './PersonalInformationResponseDto';
import type { TakerInterviewTypeDto } from './TakerInterviewTypeDto';
import {
    TakerInterviewTypeDtoFromJSON,
    TakerInterviewTypeDtoFromJSONTyped,
    TakerInterviewTypeDtoToJSON,
} from './TakerInterviewTypeDto';
import type { WorkExperienceDto } from './WorkExperienceDto';
import {
    WorkExperienceDtoFromJSON,
    WorkExperienceDtoFromJSONTyped,
    WorkExperienceDtoToJSON,
} from './WorkExperienceDto';
import type { EducationDto } from './EducationDto';
import {
    EducationDtoFromJSON,
    EducationDtoFromJSONTyped,
    EducationDtoToJSON,
} from './EducationDto';
import type { EnumTypeDto } from './EnumTypeDto';
import {
    EnumTypeDtoFromJSON,
    EnumTypeDtoFromJSONTyped,
    EnumTypeDtoToJSON,
} from './EnumTypeDto';

/**
 * 
 * @export
 * @interface ValidationInfoResponseDto
 */
export interface ValidationInfoResponseDto {
    /**
     * 
     * @type {PersonalInformationResponseDto}
     * @memberof ValidationInfoResponseDto
     */
    personalInformation: PersonalInformationResponseDto;
    /**
     * Educations
     * @type {Array<EducationDto>}
     * @memberof ValidationInfoResponseDto
     */
    educations: Array<EducationDto>;
    /**
     * Urls
     * @type {Array<ICURLDto>}
     * @memberof ValidationInfoResponseDto
     */
    urls: Array<ICURLDto>;
    /**
     * Work experiences
     * @type {Array<WorkExperienceDto>}
     * @memberof ValidationInfoResponseDto
     */
    workExperiences: Array<WorkExperienceDto>;
    /**
     * Interview types
     * @type {Array<TakerInterviewTypeDto>}
     * @memberof ValidationInfoResponseDto
     */
    requestedInterviewTypes: Array<TakerInterviewTypeDto>;
    /**
     * Interview levels
     * @type {Array<EnumTypeDto>}
     * @memberof ValidationInfoResponseDto
     */
    interviewTypes: Array<EnumTypeDto>;
    /**
     * List of available urls in the system
     * @type {Array<EnumTypeDto>}
     * @memberof ValidationInfoResponseDto
     */
    urlsTypes: Array<EnumTypeDto>;
    /**
     * List of available countries in the system
     * @type {Array<EnumTypeDto>}
     * @memberof ValidationInfoResponseDto
     */
    countries: Array<EnumTypeDto>;
    /**
     * List of available genders in the system
     * @type {Array<EnumTypeDto>}
     * @memberof ValidationInfoResponseDto
     */
    genders: Array<EnumTypeDto>;
    /**
     * List of available interview levels in the system
     * @type {Array<EnumTypeDto>}
     * @memberof ValidationInfoResponseDto
     */
    interviewLevels: Array<EnumTypeDto>;
    /**
     * List of available languages in the system
     * @type {Array<EnumTypeDto>}
     * @memberof ValidationInfoResponseDto
     */
    languages: Array<EnumTypeDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationInfoResponseDto
     */
    busyTimes: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationInfoResponseDto
     */
    requestDates?: Array<string>;
}

/**
 * Check if a given object implements the ValidationInfoResponseDto interface.
 */
export function instanceOfValidationInfoResponseDto(value: object): value is ValidationInfoResponseDto {
    if (!('personalInformation' in value) || value['personalInformation'] === undefined) return false;
    if (!('educations' in value) || value['educations'] === undefined) return false;
    if (!('urls' in value) || value['urls'] === undefined) return false;
    if (!('workExperiences' in value) || value['workExperiences'] === undefined) return false;
    if (!('requestedInterviewTypes' in value) || value['requestedInterviewTypes'] === undefined) return false;
    if (!('interviewTypes' in value) || value['interviewTypes'] === undefined) return false;
    if (!('urlsTypes' in value) || value['urlsTypes'] === undefined) return false;
    if (!('countries' in value) || value['countries'] === undefined) return false;
    if (!('genders' in value) || value['genders'] === undefined) return false;
    if (!('interviewLevels' in value) || value['interviewLevels'] === undefined) return false;
    if (!('languages' in value) || value['languages'] === undefined) return false;
    if (!('busyTimes' in value) || value['busyTimes'] === undefined) return false;
    return true;
}

export function ValidationInfoResponseDtoFromJSON(json: any): ValidationInfoResponseDto {
    return ValidationInfoResponseDtoFromJSONTyped(json, false);
}

export function ValidationInfoResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationInfoResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'personalInformation': PersonalInformationResponseDtoFromJSON(json['personalInformation']),
        'educations': ((json['educations'] as Array<any>).map(EducationDtoFromJSON)),
        'urls': ((json['urls'] as Array<any>).map(ICURLDtoFromJSON)),
        'workExperiences': ((json['workExperiences'] as Array<any>).map(WorkExperienceDtoFromJSON)),
        'requestedInterviewTypes': ((json['requestedInterviewTypes'] as Array<any>).map(TakerInterviewTypeDtoFromJSON)),
        'interviewTypes': ((json['interviewTypes'] as Array<any>).map(EnumTypeDtoFromJSON)),
        'urlsTypes': ((json['urlsTypes'] as Array<any>).map(EnumTypeDtoFromJSON)),
        'countries': ((json['countries'] as Array<any>).map(EnumTypeDtoFromJSON)),
        'genders': ((json['genders'] as Array<any>).map(EnumTypeDtoFromJSON)),
        'interviewLevels': ((json['interviewLevels'] as Array<any>).map(EnumTypeDtoFromJSON)),
        'languages': ((json['languages'] as Array<any>).map(EnumTypeDtoFromJSON)),
        'busyTimes': json['busyTimes'],
        'requestDates': json['requestDates'] == null ? undefined : json['requestDates'],
    };
}

export function ValidationInfoResponseDtoToJSON(value?: ValidationInfoResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'personalInformation': PersonalInformationResponseDtoToJSON(value['personalInformation']),
        'educations': ((value['educations'] as Array<any>).map(EducationDtoToJSON)),
        'urls': ((value['urls'] as Array<any>).map(ICURLDtoToJSON)),
        'workExperiences': ((value['workExperiences'] as Array<any>).map(WorkExperienceDtoToJSON)),
        'requestedInterviewTypes': ((value['requestedInterviewTypes'] as Array<any>).map(TakerInterviewTypeDtoToJSON)),
        'interviewTypes': ((value['interviewTypes'] as Array<any>).map(EnumTypeDtoToJSON)),
        'urlsTypes': ((value['urlsTypes'] as Array<any>).map(EnumTypeDtoToJSON)),
        'countries': ((value['countries'] as Array<any>).map(EnumTypeDtoToJSON)),
        'genders': ((value['genders'] as Array<any>).map(EnumTypeDtoToJSON)),
        'interviewLevels': ((value['interviewLevels'] as Array<any>).map(EnumTypeDtoToJSON)),
        'languages': ((value['languages'] as Array<any>).map(EnumTypeDtoToJSON)),
        'busyTimes': value['busyTimes'],
        'requestDates': value['requestDates'],
    };
}

