/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ICURLDto } from './ICURLDto';
import {
    ICURLDtoFromJSON,
    ICURLDtoFromJSONTyped,
    ICURLDtoToJSON,
} from './ICURLDto';
import type { TakerInterviewTypeDto } from './TakerInterviewTypeDto';
import {
    TakerInterviewTypeDtoFromJSON,
    TakerInterviewTypeDtoFromJSONTyped,
    TakerInterviewTypeDtoToJSON,
} from './TakerInterviewTypeDto';
import type { WorkExperienceDto } from './WorkExperienceDto';
import {
    WorkExperienceDtoFromJSON,
    WorkExperienceDtoFromJSONTyped,
    WorkExperienceDtoToJSON,
} from './WorkExperienceDto';
import type { EducationDto } from './EducationDto';
import {
    EducationDtoFromJSON,
    EducationDtoFromJSONTyped,
    EducationDtoToJSON,
} from './EducationDto';
import type { EnumTypeDto } from './EnumTypeDto';
import {
    EnumTypeDtoFromJSON,
    EnumTypeDtoFromJSONTyped,
    EnumTypeDtoToJSON,
} from './EnumTypeDto';

/**
 * 
 * @export
 * @interface TakerValidationRequestDto
 */
export interface TakerValidationRequestDto {
    /**
     * First name
     * @type {string}
     * @memberof TakerValidationRequestDto
     */
    firstName: string;
    /**
     * Last name
     * @type {string}
     * @memberof TakerValidationRequestDto
     */
    lastName: string;
    /**
     * 
     * @type {EnumTypeDto}
     * @memberof TakerValidationRequestDto
     */
    country: EnumTypeDto;
    /**
     * Date of Birth
     * @type {Date}
     * @memberof TakerValidationRequestDto
     */
    dateOfBirth: Date;
    /**
     * Gender
     * @type {string}
     * @memberof TakerValidationRequestDto
     */
    gender: TakerValidationRequestDtoGenderEnum;
    /**
     * About me
     * @type {string}
     * @memberof TakerValidationRequestDto
     */
    aboutMe?: string;
    /**
     * Preferred languages
     * @type {Array<string>}
     * @memberof TakerValidationRequestDto
     */
    preferredLanguages: Array<TakerValidationRequestDtoPreferredLanguagesEnum>;
    /**
     * Educations
     * @type {Array<EducationDto>}
     * @memberof TakerValidationRequestDto
     */
    educations: Array<EducationDto>;
    /**
     * Work experiences
     * @type {Array<WorkExperienceDto>}
     * @memberof TakerValidationRequestDto
     */
    workExperiences: Array<WorkExperienceDto>;
    /**
     * Urls
     * @type {Array<ICURLDto>}
     * @memberof TakerValidationRequestDto
     */
    urls: Array<ICURLDto>;
    /**
     * Interview types
     * @type {Array<TakerInterviewTypeDto>}
     * @memberof TakerValidationRequestDto
     */
    interviewTypes: Array<TakerInterviewTypeDto>;
    /**
     * Dates
     * @type {Array<Date>}
     * @memberof TakerValidationRequestDto
     */
    dates: Array<Date>;
}


/**
 * @export
 */
export const TakerValidationRequestDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;
export type TakerValidationRequestDtoGenderEnum = typeof TakerValidationRequestDtoGenderEnum[keyof typeof TakerValidationRequestDtoGenderEnum];

/**
 * @export
 */
export const TakerValidationRequestDtoPreferredLanguagesEnum = {
    Armenian: 'ARMENIAN',
    English: 'ENGLISH',
    Russian: 'RUSSIAN'
} as const;
export type TakerValidationRequestDtoPreferredLanguagesEnum = typeof TakerValidationRequestDtoPreferredLanguagesEnum[keyof typeof TakerValidationRequestDtoPreferredLanguagesEnum];


/**
 * Check if a given object implements the TakerValidationRequestDto interface.
 */
export function instanceOfTakerValidationRequestDto(value: object): value is TakerValidationRequestDto {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('dateOfBirth' in value) || value['dateOfBirth'] === undefined) return false;
    if (!('gender' in value) || value['gender'] === undefined) return false;
    if (!('preferredLanguages' in value) || value['preferredLanguages'] === undefined) return false;
    if (!('educations' in value) || value['educations'] === undefined) return false;
    if (!('workExperiences' in value) || value['workExperiences'] === undefined) return false;
    if (!('urls' in value) || value['urls'] === undefined) return false;
    if (!('interviewTypes' in value) || value['interviewTypes'] === undefined) return false;
    if (!('dates' in value) || value['dates'] === undefined) return false;
    return true;
}

export function TakerValidationRequestDtoFromJSON(json: any): TakerValidationRequestDto {
    return TakerValidationRequestDtoFromJSONTyped(json, false);
}

export function TakerValidationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TakerValidationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'country': EnumTypeDtoFromJSON(json['country']),
        'dateOfBirth': (new Date(json['dateOfBirth'])),
        'gender': json['gender'],
        'aboutMe': json['aboutMe'] == null ? undefined : json['aboutMe'],
        'preferredLanguages': json['preferredLanguages'],
        'educations': ((json['educations'] as Array<any>).map(EducationDtoFromJSON)),
        'workExperiences': ((json['workExperiences'] as Array<any>).map(WorkExperienceDtoFromJSON)),
        'urls': ((json['urls'] as Array<any>).map(ICURLDtoFromJSON)),
        'interviewTypes': ((json['interviewTypes'] as Array<any>).map(TakerInterviewTypeDtoFromJSON)),
        'dates': json['dates'],
    };
}

export function TakerValidationRequestDtoToJSON(value?: TakerValidationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'country': EnumTypeDtoToJSON(value['country']),
        'dateOfBirth': ((value['dateOfBirth']).toISOString()),
        'gender': value['gender'],
        'aboutMe': value['aboutMe'],
        'preferredLanguages': value['preferredLanguages'],
        'educations': ((value['educations'] as Array<any>).map(EducationDtoToJSON)),
        'workExperiences': ((value['workExperiences'] as Array<any>).map(WorkExperienceDtoToJSON)),
        'urls': ((value['urls'] as Array<any>).map(ICURLDtoToJSON)),
        'interviewTypes': ((value['interviewTypes'] as Array<any>).map(TakerInterviewTypeDtoToJSON)),
        'dates': value['dates'],
    };
}

