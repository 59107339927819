import React, { useEffect, useLayoutEffect, useState } from 'react'
import PersonalInfoSection from './PersonalInfoSection'
import EducationSection from './EducationSection'
import CompanySection from './CompanySection'
import UrlSection from './UrlSection'
import { type VerificationModalEnums } from '../VerifyModal/VerifyModal'

import InterviewSection from './InterviewSection'
import ApiManager from '../../manager/ApiManager'
import {
  type GetSectionInformationRequest,
  ICUserControllerApi,
  TakerControllerApi,
  type TakerRejectionDto,
  type ValidationInfoResponseDto
} from '../../api/ic'

interface PersonalInfoSectionProps {
  userId?: string
  setTakerRejection?: (dto: TakerRejectionDto) => void
  enumValues?: VerificationModalEnums
  setRequestedDates?: React.Dispatch<React.SetStateAction<string[]>>
  setName?: React.Dispatch<React.SetStateAction<string>>
  setSurname?: React.Dispatch<React.SetStateAction<string>>
  setEmail?: React.Dispatch<React.SetStateAction<string | undefined>>
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

function InfoSection (props: PersonalInfoSectionProps) {
  const [ICUserInfo, setICUserInfo] = useState<ValidationInfoResponseDto>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [enumsValues, setEnumValues] = useState<VerificationModalEnums>(
    {
      genders: [],
      levels: [],
      types: [],
      urls: [],
      languages: [],
      countries: []
    }
  )

  const takerApi = ApiManager.getInstance(TakerControllerApi)
  const userAPi = ApiManager.getInstance(ICUserControllerApi)

  useLayoutEffect(() => {
    setIsLoading(true)
    if (props.setIsLoading) {
      props.setIsLoading(true)
    }
    void takerApi.getTakerValidationAllInfo().then(res => {
      setICUserInfo(res)
      if (props.setSurname && props.setName && props.setEmail) {
        props.setSurname(res.personalInformation.lastname)
        props.setName(res.personalInformation.firstname)
        props.setEmail(res.personalInformation.email)
      }
      if (props.setRequestedDates) {
        props.setRequestedDates(res.requestDates ?? [])
      }
      setEnumValues({
        genders: res.genders,
        urls: res.urlsTypes,
        levels: res.interviewLevels,
        types: res.interviewTypes,
        languages: res.languages,
        countries: res.countries
      })
      setIsLoading(false)
      if (props.setIsLoading) {
        props.setIsLoading(false)
      }
    }
    )
  }, [])

  const isAdmin = !!props.userId
  useEffect(() => {
    const params: GetSectionInformationRequest = {
      urlSection: true,
      educationSection: true,
      workSection: true
    }
    if (props.userId) {
      params.userId = props.userId
      params.takerRejection = true
    }
    void userAPi.getSectionInformation(params)
      .then(res => {
        if (res.takerRejection && props.setTakerRejection) {
          props.setTakerRejection(res.takerRejection)
        }
      }).catch(() => {
      })
  }, [])
  return (
    <>
      {!isLoading && ICUserInfo && (
        <>
          <PersonalInfoSection
            setName={props.setName}
            setSurname={props.setSurname}
            editable={!isAdmin ?? false}
            genderValues={enumsValues.genders}
            countryValues={enumsValues.countries}
            languageValues={enumsValues.languages}
            ICUserInfo={ICUserInfo.personalInformation}/>
          {ICUserInfo.educations?.length
            ? <EducationSection editable={false} educations={ICUserInfo.educations}/>
            : <></>
          }
          {ICUserInfo.workExperiences?.length ? <CompanySection editable={false} workExperiences={ICUserInfo.workExperiences}/> : <></>}
          {ICUserInfo.urls?.length ? <UrlSection
            urlValues={enumsValues.urls}
            editable={false}
            urls={ICUserInfo.urls}/> : <></>}
          {ICUserInfo.requestedInterviewTypes?.length ? <InterviewSection
            editable={false}
            interviewLevelValues={enumsValues.levels}
            interviewTypeValues={enumsValues.types}
            interviewsInfo={ICUserInfo.requestedInterviewTypes}/> : <></>
          }
        </>
      )}
    </>
  )
}

export default InfoSection
