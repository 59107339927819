import React, { useState } from 'react'
import ColoredText from '../components/text/ColoredText'
import { COLORS } from '../util/Consts'
import StandardButton from '../components/buttons/StandardButton'
import { ButtonModeValues } from '../components/modes/ButtonMode'
import { Popover } from '@mui/material'
import interviewer_small from '../components/svg/resources/interviewer_small.svg'
import { capitalizeFirstLetter, extractDateAndTime, formatFullName, isXMinutesBefore } from '../util/Helper'
import StandardModal from '../components/modal/StandardModal'
import { FontModeValues } from '../components/modes/FontMode'
import toast from 'react-hot-toast'
import InterviewCancellationRadio from './InterviewCancellationRadio'
import { useNavigate } from 'react-router-dom'
import { type GetInterviewDto, GetInterviewDtoStatusEnum, InterviewControllerApi } from '../api/ic'
import ApiManager from '../manager/ApiManager'
import InterviewStatus from './InterviewStatus'

interface GiverUpcomingCardProps {
  interview: GetInterviewDto
  setTriggerUpcomingAndCurrentInterviewDataFetching: React.Dispatch<React.SetStateAction<boolean>>
  setIsMainModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function GiverUpcomingCard (props: GiverUpcomingCardProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [anchorFullName, setAnchorFullName] = React.useState<HTMLElement | null>(null)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [cancellationReason, SetCancellationReason] = React.useState<string>('')
  const [otherMessage, setOtherMessage] = useState<string>('')

  const minutesBeforeToBeClickable: number = Number(process.env.REACT_APP_MINUTES_BEFORE_JOIN_PAGE_IS_AVAILABLE) || 30
  const interviewObj = props.interview
  const interviewApi = ApiManager.getInstance(InterviewControllerApi)

  const isOngoing = props.interview.status === GetInterviewDtoStatusEnum.Started

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFullName(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorFullName(null)
  }

  const openFullName = Boolean(anchorFullName)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'cancel-interview' : undefined

  const navigate = useNavigate()
  return (
    <div
      style={{
        width: '200px',
        height: '244px',
        padding: '8px 12px 8px 12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: 'white',
        borderRadius: '10px',
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
        position: 'relative'
      }}>
      <div className={'d-inline-flex flex-row align-items-center justify-content-between'} style={{
        cursor: 'pointer',
        margin: '2px 0px'
      }}>
        <div style={{ position: 'absolute', right: -10, top: -10 }}>
          {isOngoing && <InterviewStatus
            type={'modal'}
            interviewStatusString={'Ongoing'}/>}
        </div>
        <ColoredText singleText={`${extractDateAndTime(interviewObj.acceptedDate?.toUTCString()).date}`}
          color={COLORS.MAIN_COLOR}
          fontSize={'18px'}/>
        {!isOngoing && <div style={{
          fontWeight: 'bold',
          fontSize: '16px',
          userSelect: 'none'
        }}
          onClick={handleClick}>
          ...
        </div>}
        <Popover
          id={id}
          disableRestoreFocus
          open={open}
          anchorEl={anchorEl}
          slotProps={{
            paper: {
              sx: {
                boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)'
              }
            }
          }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <div
            onClick={() => { setIsOpen(true) }}
            style={{
              padding: '5px 10px 5px 10px',
              userSelect: 'none',
              cursor: 'pointer'
            }}>
            Cancel
          </div>
        </Popover>
      </div>
      <div className={'d-flex flex-row justify-content-between align-items-center'}>
        <div
          style = {{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px'
          }}>
          <ColoredText singleText={'Time'} color = {COLORS.MAIN_COLOR}/>
          <ColoredText singleText={'Language'} color = {COLORS.MAIN_COLOR}/>
          <ColoredText singleText={'Level'} color = {COLORS.MAIN_COLOR}/>
          <ColoredText singleText={'Taker'} color = {COLORS.MAIN_COLOR}/>
        </div>
        <div
          style = {{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'right'
          }}>
          <ColoredText singleText={extractDateAndTime(interviewObj.acceptedDate?.toUTCString()).time}/>
          <ColoredText singleText={interviewObj.acceptedLanguage ? `${capitalizeFirstLetter(interviewObj.acceptedLanguage.toLowerCase())}` : 'Unknown'}/>
          <ColoredText singleText={interviewObj.level ? `${capitalizeFirstLetter(interviewObj.level.toLowerCase())}` : 'Unknown'}/>
          <div className={'d-flex flex-row gap-2 align-items-center justify-content-end'}>
            <img height={'20px'} width={'20px'}
              src={interviewer_small} alt={' '}/>
            <span>
              <span
                aria-owns={openFullName ? 'show-full-name' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}>
                <ColoredText
                  whiteSpace={'nowrap'}
                  singleText={`${formatFullName(interviewObj.taker?.id ? (props.interview.taker?.firstName + ' ' + props.interview.taker?.lastName) : 'Unknown')}`}/>
              </span>
              <Popover
                id="show-full-name"
                sx={{
                  pointerEvents: 'none'
                }}
                slotProps={{
                  paper: {
                    sx: {
                      boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)'
                    }
                  }
                }}
                open={openFullName}
                anchorEl={anchorFullName}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus>
                <span className={'d-flex px-2 py-1 justify-content-center'}>{props.interview.taker?.firstName + ' ' + props.interview.taker?.lastName}</span>
              </Popover>
            </span>
          </div>
        </div>
      </div>
      <StandardButton
        text= {'Join Waiting Room'}
        clickable={isXMinutesBefore(interviewObj.acceptedDate?.toUTCString(), minutesBeforeToBeClickable)} // TODO: change to 5
        sizeMode= {ButtonModeValues.MAX_WIDTH}
        onClick={() => { navigate(`/interview/${interviewObj.id}/join`, { replace: true }) }}
        backgroundColorMode='light'/>
      <StandardModal isOpen={isOpen} closeWhenClickingOutOfModal={true}
        title={'Interview Cancellation'}
        width={`${(screen.availWidth * 38 / 100)}px`}
        height={`${(screen.availHeight * 48 / 100)}px`}
        footerComponent={
          <>
            <ColoredText singleText={'Discard'} cursor={'pointer'}
              onClick={ () => { setIsOpen(false) } } />
            <StandardButton
              clickable={(cancellationReason !== '' && cancellationReason !== 'Other') || (cancellationReason === 'Other' && otherMessage !== '')}
              text={'Cancel'}
              sizeMode={ButtonModeValues.SMALL}
              onClick={ () => {
                const reason = cancellationReason === 'Other' ? otherMessage : cancellationReason
                void interviewApi.cancelInterview({
                  interviewCancellationDto: { interviewId: interviewObj.id, reason }
                })
                  .then(() => {
                    toast.success('Successfully cancelled interview')
                    setIsOpen(false)
                    props.setTriggerUpcomingAndCurrentInterviewDataFetching(true)
                  }).catch(() => { props.setTriggerUpcomingAndCurrentInterviewDataFetching(true) })
              }} />
          </>}>
        <>
          <div style={{ marginBottom: '80px' }} className={'d-flex flex-column gap-1'}>
            <ColoredText singleText={'Reason'} fontSize={FontModeValues.LARGE}/>
            <div className={'mt-1'}>
              <InterviewCancellationRadio
                otherMessage={otherMessage}
                setOtherMessage={setOtherMessage}
                selectedOption={cancellationReason}
                setSelection={SetCancellationReason}
                options={[
                  'Schedule Conflict',
                  'Health Issues',
                  'Not interested anymore',
                  'Technical Difficulties',
                  'Problem with taker or giver',
                  'Other']
              }/>
            </div>
          </div>
        </>
      </StandardModal>
    </div>
  )
}

export default GiverUpcomingCard
