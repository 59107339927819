/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetInterviewQuestionTemplateDto,
  GetInterviewQuestionTemplateWrapperDto,
  GetInterviewQuestionWrapperDto,
  InterviewQuestionAnswerRequestDto,
  InterviewQuestionInsertionResponse,
} from '../models/index';
import {
    GetInterviewQuestionTemplateDtoFromJSON,
    GetInterviewQuestionTemplateDtoToJSON,
    GetInterviewQuestionTemplateWrapperDtoFromJSON,
    GetInterviewQuestionTemplateWrapperDtoToJSON,
    GetInterviewQuestionWrapperDtoFromJSON,
    GetInterviewQuestionWrapperDtoToJSON,
    InterviewQuestionAnswerRequestDtoFromJSON,
    InterviewQuestionAnswerRequestDtoToJSON,
    InterviewQuestionInsertionResponseFromJSON,
    InterviewQuestionInsertionResponseToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface DontAskForNextRequest {
    interviewId: string;
}

export interface GenerateTemplateRequest {
    interviewId: string;
}

export interface GetTemplateRequest {
    interviewId: string;
}

export interface InsertRequest {
    excelFile: Blob;
}

export interface NextRequest {
    interviewId: string;
    interviewQuestionAnswerRequestDto: InterviewQuestionAnswerRequestDto;
}

/**
 * 
 */
export class InterviewQuestionControllerApi extends runtime.BaseAPI {

    /**
     * Make do not ask again for next question
     * Make do not ask again
     */
    async dontAskForNextRaw(requestParameters: DontAskForNextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['interviewId'] == null) {
            throw new runtime.RequiredError(
                'interviewId',
                'Required parameter "interviewId" was null or undefined when calling dontAskForNext().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/question/template/{interviewId}/dontAskForNext`.replace(`{${"interviewId"}}`, encodeURIComponent(String(requestParameters['interviewId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Make do not ask again for next question
     * Make do not ask again
     */
    async dontAskForNext(requestParameters: DontAskForNextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.dontAskForNextRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate template for interview questions
     * Generate template
     */
    async generateTemplateRaw(requestParameters: GenerateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewQuestionTemplateDto>> {
        if (requestParameters['interviewId'] == null) {
            throw new runtime.RequiredError(
                'interviewId',
                'Required parameter "interviewId" was null or undefined when calling generateTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/question/template/{interviewId}/generate`.replace(`{${"interviewId"}}`, encodeURIComponent(String(requestParameters['interviewId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewQuestionTemplateDtoFromJSON(jsonValue));
    }

    /**
     * Generate template for interview questions
     * Generate template
     */
    async generateTemplate(requestParameters: GenerateTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewQuestionTemplateDto> {
        const response = await this.generateTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get generated template for interview questions
     * Get generated template
     */
    async getTemplateRaw(requestParameters: GetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewQuestionTemplateWrapperDto>> {
        if (requestParameters['interviewId'] == null) {
            throw new runtime.RequiredError(
                'interviewId',
                'Required parameter "interviewId" was null or undefined when calling getTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/question/template/{interviewId}`.replace(`{${"interviewId"}}`, encodeURIComponent(String(requestParameters['interviewId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewQuestionTemplateWrapperDtoFromJSON(jsonValue));
    }

    /**
     * Get generated template for interview questions
     * Get generated template
     */
    async getTemplate(requestParameters: GetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewQuestionTemplateWrapperDto> {
        const response = await this.getTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Inserts questions from the excel file
     * Insert questions
     */
    async insertRaw(requestParameters: InsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InterviewQuestionInsertionResponse>> {
        if (requestParameters['excelFile'] == null) {
            throw new runtime.RequiredError(
                'excelFile',
                'Required parameter "excelFile" was null or undefined when calling insert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['excelFile'] != null) {
            formParams.append('excelFile', requestParameters['excelFile'] as any);
        }

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/question/insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewQuestionInsertionResponseFromJSON(jsonValue));
    }

    /**
     * Inserts questions from the excel file
     * Insert questions
     */
    async insert(requestParameters: InsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InterviewQuestionInsertionResponse> {
        const response = await this.insertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Created answer entity, updates current question id on the template
     * Next question handler
     */
    async nextRaw(requestParameters: NextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewQuestionWrapperDto>> {
        if (requestParameters['interviewId'] == null) {
            throw new runtime.RequiredError(
                'interviewId',
                'Required parameter "interviewId" was null or undefined when calling next().'
            );
        }

        if (requestParameters['interviewQuestionAnswerRequestDto'] == null) {
            throw new runtime.RequiredError(
                'interviewQuestionAnswerRequestDto',
                'Required parameter "interviewQuestionAnswerRequestDto" was null or undefined when calling next().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/question/template/{interviewId}/next`.replace(`{${"interviewId"}}`, encodeURIComponent(String(requestParameters['interviewId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewQuestionAnswerRequestDtoToJSON(requestParameters['interviewQuestionAnswerRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewQuestionWrapperDtoFromJSON(jsonValue));
    }

    /**
     * Created answer entity, updates current question id on the template
     * Next question handler
     */
    async next(requestParameters: NextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewQuestionWrapperDto> {
        const response = await this.nextRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
