/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnumTypeDto } from './EnumTypeDto';
import {
    EnumTypeDtoFromJSON,
    EnumTypeDtoFromJSONTyped,
    EnumTypeDtoToJSON,
} from './EnumTypeDto';

/**
 * 
 * @export
 * @interface PersonalInformationRequestDto
 */
export interface PersonalInformationRequestDto {
    /**
     * First name
     * @type {string}
     * @memberof PersonalInformationRequestDto
     */
    firstname?: string;
    /**
     * Last name
     * @type {string}
     * @memberof PersonalInformationRequestDto
     */
    lastname?: string;
    /**
     * 
     * @type {EnumTypeDto}
     * @memberof PersonalInformationRequestDto
     */
    country?: EnumTypeDto;
    /**
     * Date of birth
     * @type {Date}
     * @memberof PersonalInformationRequestDto
     */
    dateOfBirth?: Date;
    /**
     * Gender
     * @type {string}
     * @memberof PersonalInformationRequestDto
     */
    gender?: PersonalInformationRequestDtoGenderEnum;
    /**
     * About me
     * @type {string}
     * @memberof PersonalInformationRequestDto
     */
    aboutMe?: string;
    /**
     * Preferred languages
     * @type {Array<string>}
     * @memberof PersonalInformationRequestDto
     */
    preferredLanguages?: Array<PersonalInformationRequestDtoPreferredLanguagesEnum>;
}


/**
 * @export
 */
export const PersonalInformationRequestDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;
export type PersonalInformationRequestDtoGenderEnum = typeof PersonalInformationRequestDtoGenderEnum[keyof typeof PersonalInformationRequestDtoGenderEnum];

/**
 * @export
 */
export const PersonalInformationRequestDtoPreferredLanguagesEnum = {
    Armenian: 'ARMENIAN',
    English: 'ENGLISH',
    Russian: 'RUSSIAN'
} as const;
export type PersonalInformationRequestDtoPreferredLanguagesEnum = typeof PersonalInformationRequestDtoPreferredLanguagesEnum[keyof typeof PersonalInformationRequestDtoPreferredLanguagesEnum];


/**
 * Check if a given object implements the PersonalInformationRequestDto interface.
 */
export function instanceOfPersonalInformationRequestDto(value: object): value is PersonalInformationRequestDto {
    return true;
}

export function PersonalInformationRequestDtoFromJSON(json: any): PersonalInformationRequestDto {
    return PersonalInformationRequestDtoFromJSONTyped(json, false);
}

export function PersonalInformationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonalInformationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'firstname': json['firstname'] == null ? undefined : json['firstname'],
        'lastname': json['lastname'] == null ? undefined : json['lastname'],
        'country': json['country'] == null ? undefined : EnumTypeDtoFromJSON(json['country']),
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : (new Date(json['dateOfBirth'])),
        'gender': json['gender'] == null ? undefined : json['gender'],
        'aboutMe': json['aboutMe'] == null ? undefined : json['aboutMe'],
        'preferredLanguages': json['preferredLanguages'] == null ? undefined : json['preferredLanguages'],
    };
}

export function PersonalInformationRequestDtoToJSON(value?: PersonalInformationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstname': value['firstname'],
        'lastname': value['lastname'],
        'country': EnumTypeDtoToJSON(value['country']),
        'dateOfBirth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth']).toISOString()),
        'gender': value['gender'],
        'aboutMe': value['aboutMe'],
        'preferredLanguages': value['preferredLanguages'],
    };
}

