/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ICExceptionDto
 */
export interface ICExceptionDto {
    /**
     * Reason for the exception
     * @type {string}
     * @memberof ICExceptionDto
     */
    reason: ICExceptionDtoReasonEnum;
    /**
     * Message
     * @type {string}
     * @memberof ICExceptionDto
     */
    message: string;
    /**
     * HTTP status code
     * @type {number}
     * @memberof ICExceptionDto
     */
    code: number;
}


/**
 * @export
 */
export const ICExceptionDtoReasonEnum = {
    DefaultException: 'DEFAULT_EXCEPTION',
    AccessToEndpointDeniedException: 'ACCESS_TO_ENDPOINT_DENIED_EXCEPTION',
    NoHandlerFoundException: 'NO_HANDLER_FOUND_EXCEPTION',
    MethodNotAllowedException: 'METHOD_NOT_ALLOWED_EXCEPTION',
    NoUserToVerifyException: 'NO_USER_TO_VERIFY_EXCEPTION',
    OtpNotGeneratedException: 'OTP_NOT_GENERATED_EXCEPTION',
    OtpExpiredException: 'OTP_EXPIRED_EXCEPTION',
    TooManyAttemptsException: 'TOO_MANY_ATTEMPTS_EXCEPTION',
    TooManyLoginAttemptsException: 'TOO_MANY_LOGIN_ATTEMPTS_EXCEPTION',
    EmailWasSentRecentlyException: 'EMAIL_WAS_SENT_RECENTLY_EXCEPTION',
    WrongOtpException: 'WRONG_OTP_EXCEPTION',
    FailedToSendEmailException: 'FAILED_TO_SEND_EMAIL_EXCEPTION',
    UserAlreadyRegisteredException: 'USER_ALREADY_REGISTERED_EXCEPTION',
    NoSuchControllerException: 'NO_SUCH_CONTROLLER_EXCEPTION',
    EmailAndRoleExistException: 'EMAIL_AND_ROLE_EXIST_EXCEPTION',
    EmailMissmatchStrangeRequestException: 'EMAIL_MISSMATCH_STRANGE_REQUEST_EXCEPTION',
    NoSessionIdProvidedInPayloadException: 'NO_SESSION_ID_PROVIDED_IN_PAYLOAD_EXCEPTION',
    InvalidSessionTokenException: 'INVALID_SESSION_TOKEN_EXCEPTION',
    NoUserWithSuchSessionIdException: 'NO_USER_WITH_SUCH_SESSION_ID_EXCEPTION',
    WrongLoginCredentialsException: 'WRONG_LOGIN_CREDENTIALS_EXCEPTION',
    NoUserWithSuchEmailException: 'NO_USER_WITH_SUCH_EMAIL_EXCEPTION',
    UnableToConvertRolesException: 'UNABLE_TO_CONVERT_ROLES_EXCEPTION',
    InterviewTypeNotUniqueException: 'INTERVIEW_TYPE_NOT_UNIQUE_EXCEPTION',
    InterviewTypeNotExistException: 'INTERVIEW_TYPE_NOT_EXIST_EXCEPTION',
    DeletingAvailableInterviewTypeException: 'DELETING_AVAILABLE_INTERVIEW_TYPE_EXCEPTION',
    SessionTypeIsNotVerifiableException: 'SESSION_TYPE_IS_NOT_VERIFIABLE_EXCEPTION',
    IdOrTypeMustBeProvidedException: 'ID_OR_TYPE_MUST_BE_PROVIDED_EXCEPTION',
    InterviewWithThisIdDoesntExistException: 'INTERVIEW_WITH_THIS_ID_DOESNT_EXIST_EXCEPTION',
    InterviewAccessDeniedForCurrentUserException: 'INTERVIEW_ACCESS_DENIED_FOR_CURRENT_USER_EXCEPTION',
    NoUserExistWithThisIdException: 'NO_USER_EXIST_WITH_THIS_ID_EXCEPTION',
    UserAlreadyRequestedForBeingTakerException: 'USER_ALREADY_REQUESTED_FOR_BEING_TAKER_EXCEPTION',
    NotValidatedAsTakerToGetSessionException: 'NOT_VALIDATED_AS_TAKER_TO_GET_SESSION_EXCEPTION',
    UserAlreadyValidatedAsTakerException: 'USER_ALREADY_VALIDATED_AS_TAKER_EXCEPTION',
    AtLeastOneEducationShouldBeGivenException: 'AT_LEAST_ONE_EDUCATION_SHOULD_BE_GIVEN_EXCEPTION',
    AtLeastOneWorkExperienceShouldBeGivenException: 'AT_LEAST_ONE_WORK_EXPERIENCE_SHOULD_BE_GIVEN_EXCEPTION',
    AtLeastOnePreferredLanguageShouldBeGivenException: 'AT_LEAST_ONE_PREFERRED_LANGUAGE_SHOULD_BE_GIVEN_EXCEPTION',
    UserBecameTakerException: 'USER_BECAME_TAKER_EXCEPTION',
    TakerRequestWasRejected: 'TAKER_REQUEST_WAS_REJECTED',
    TakerRequestedAgain: 'TAKER_REQUESTED_AGAIN',
    UserAlreadyVerifiedAsTaker: 'USER_ALREADY_VERIFIED_AS_TAKER',
    TakerRequestAlreadyRejectedException: 'TAKER_REQUEST_ALREADY_REJECTED_EXCEPTION',
    IdShouldBeGivenException: 'ID_SHOULD_BE_GIVEN_EXCEPTION',
    TakerRejectionReasonRequiredException: 'TAKER_REJECTION_REASON_REQUIRED_EXCEPTION',
    InterviewWithTypeAlreadyRequestedException: 'INTERVIEW_WITH_TYPE_ALREADY_REQUESTED_EXCEPTION',
    NoValidDatesException: 'NO_VALID_DATES_EXCEPTION',
    InterviewAlreadyAcceptedException: 'INTERVIEW_ALREADY_ACCEPTED_EXCEPTION',
    NoAvailableInterviewsToAcceptException: 'NO_AVAILABLE_INTERVIEWS_TO_ACCEPT_EXCEPTION',
    UserAgentParseException: 'USER_AGENT_PARSE_EXCEPTION',
    FingerPrintIsNotProvidedException: 'FINGER_PRINT_IS_NOT_PROVIDED_EXCEPTION',
    SessionExpiredException: 'SESSION_EXPIRED_EXCEPTION',
    RefreshTokenExpiredException: 'REFRESH_TOKEN_EXPIRED_EXCEPTION',
    NoMatchingLanguagesException: 'NO_MATCHING_LANGUAGES_EXCEPTION',
    FieldIsRequiredException: 'FIELD_IS_REQUIRED_EXCEPTION',
    InterviewNotAcceptedException: 'INTERVIEW_NOT_ACCEPTED_EXCEPTION',
    InterviewNotAvailableYetException: 'INTERVIEW_NOT_AVAILABLE_YET_EXCEPTION',
    NoValidationInterviewException: 'NO_VALIDATION_INTERVIEW_EXCEPTION',
    FromDateShouldNotBeNullException: 'FROM_DATE_SHOULD_NOT_BE_NULL_EXCEPTION',
    ToDateShouldNotBeNullException: 'TO_DATE_SHOULD_NOT_BE_NULL_EXCEPTION',
    FromDateShouldBeBeforeToDateException: 'FROM_DATE_SHOULD_BE_BEFORE_TO_DATE_EXCEPTION',
    GiverMustBeRejectedForTaker: 'GIVER_MUST_BE_REJECTED_FOR_TAKER',
    InvalidKeyException: 'INVALID_KEY_EXCEPTION',
    NoAdminFoundException: 'NO_ADMIN_FOUND_EXCEPTION',
    UserNotRequestedForBeingTakerException: 'USER_NOT_REQUESTED_FOR_BEING_TAKER_EXCEPTION',
    UserAcceptedForInterview: 'USER_ACCEPTED_FOR_INTERVIEW',
    DuplicateException: 'DUPLICATE_EXCEPTION',
    NotEnoughBalanceException: 'NOT_ENOUGH_BALANCE_EXCEPTION',
    NotEnoughFrozenBalanceException: 'NOT_ENOUGH_FROZEN_BALANCE_EXCEPTION',
    NoBalanceExistWithThisTypeException: 'NO_BALANCE_EXIST_WITH_THIS_TYPE_EXCEPTION',
    NoBalanceCardExistWithThisIdException: 'NO_BALANCE_CARD_EXIST_WITH_THIS_ID_EXCEPTION',
    MaxBankCardsReachedException: 'MAX_BANK_CARDS_REACHED_EXCEPTION',
    NoSuchObjectWithId: 'NO_SUCH_OBJECT_WITH_ID',
    InvalidFileException: 'INVALID_FILE_EXCEPTION',
    FileProcessingException: 'FILE_PROCESSING_EXCEPTION',
    DatabaseException: 'DATABASE_EXCEPTION',
    InterviewQuestionTemplateException: 'INTERVIEW_QUESTION_TEMPLATE_EXCEPTION',
    VideoClientException: 'VIDEO_CLIENT_EXCEPTION',
    NullValueIsNotAcceptedException: 'NULL_VALUE_IS_NOT_ACCEPTED_EXCEPTION',
    InterviewAlreadyMarkedAsStartedByGiverException: 'INTERVIEW_ALREADY_MARKED_AS_STARTED_BY_GIVER_EXCEPTION',
    InterviewAlreadyMarkedAsStartedByTakerException: 'INTERVIEW_ALREADY_MARKED_AS_STARTED_BY_TAKER_EXCEPTION',
    InterviewAlreadyMarkedAsStartedException: 'INTERVIEW_ALREADY_MARKED_AS_STARTED_EXCEPTION',
    UnsupportedOperationException: 'UNSUPPORTED_OPERATION_EXCEPTION'
} as const;
export type ICExceptionDtoReasonEnum = typeof ICExceptionDtoReasonEnum[keyof typeof ICExceptionDtoReasonEnum];


/**
 * Check if a given object implements the ICExceptionDto interface.
 */
export function instanceOfICExceptionDto(value: object): value is ICExceptionDto {
    if (!('reason' in value) || value['reason'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('code' in value) || value['code'] === undefined) return false;
    return true;
}

export function ICExceptionDtoFromJSON(json: any): ICExceptionDto {
    return ICExceptionDtoFromJSONTyped(json, false);
}

export function ICExceptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICExceptionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'reason': json['reason'],
        'message': json['message'],
        'code': json['code'],
    };
}

export function ICExceptionDtoToJSON(value?: ICExceptionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reason': value['reason'],
        'message': value['message'],
        'code': value['code'],
    };
}

