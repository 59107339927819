/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoginSessionDto,
} from '../models/index';
import {
    LoginSessionDtoFromJSON,
    LoginSessionDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface TerminateRequest {
    uuid: string;
}

/**
 * 
 */
export class SessionControllerApi extends runtime.BaseAPI {

    /**
     * Get all sessions
     * Get all sessions
     */
    async getAllSessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LoginSessionDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/session/allLogins`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoginSessionDtoFromJSON));
    }

    /**
     * Get all sessions
     * Get all sessions
     */
    async getAllSessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LoginSessionDto>> {
        const response = await this.getAllSessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Terminate session
     * Terminate session
     */
    async terminateRaw(requestParameters: TerminateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginSessionDto>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling terminate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['uuid'] != null) {
            queryParameters['uuid'] = requestParameters['uuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/session/terminate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginSessionDtoFromJSON(jsonValue));
    }

    /**
     * Terminate session
     * Terminate session
     */
    async terminate(requestParameters: TerminateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginSessionDto> {
        const response = await this.terminateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
