import React, { useEffect, useState } from 'react'
import InterviewCard from './InterviewCard'
import { Skeleton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  addTakerValidationInterviewType,
  getTakerInterviewTypes,
  removeTakerValidationInterviewType
} from '../features/TakerInfoSlice'
import { type GetInterviewDto, type GetInterviewTypeDto, InterviewTypeControllerApi } from '../api/ic'
import ApiManager from '../manager/ApiManager'

interface InterviewCardsInterface {
  mode?: 'small'
  selectable?: boolean
}

function InterviewCards (props: InterviewCardsInterface) {
  const [loading, setLoading] = useState(true)
  const [objs, setObjs] = useState<GetInterviewTypeDto[]>([])
  const [triggerInterviewRequest, setTriggerInterviewRequest] = useState(true)
  const dispatch = useDispatch()
  const selectedInterviewTypes = useSelector(getTakerInterviewTypes)

  const interviewTypeApi = ApiManager.getInstance(InterviewTypeControllerApi)

  useEffect(() => {
    if (triggerInterviewRequest) {
      void interviewTypeApi.getAllAvailablePerUser()
        .then(res => {
          res.sort((a, b) => {
            if (b.hasUpcomingInterviews && !a.hasUpcomingInterviews) {
              return 1
            }
            if (a.hasUpcomingInterviews && !b.hasUpcomingInterviews) {
              return -1
            }
            if (b.hasRequestedInterviews && !a.hasRequestedInterviews) {
              return 1
            }
            if (a.hasRequestedInterviews && !b.hasRequestedInterviews) {
              return -1
            }
            return 0
          })
          setObjs(res)
          setLoading(false)
        }).catch(() => {})
      setTriggerInterviewRequest(false)
    }
  }, [triggerInterviewRequest])

  const onSelect = (obj: GetInterviewTypeDto) => {
    dispatch(addTakerValidationInterviewType(obj))
  }

  const onDeSelect = (obj: GetInterviewTypeDto) => {
    dispatch(removeTakerValidationInterviewType(obj))
  }

  return (
    <div
      className="container"
      style={{ marginTop: '50px' }}>
      <div className="row container-md">
        {objs.length
          ? objs.map(obj => (
            <div className={ props.mode === 'small' ? 'col-xl-2 col-lg-4 col-md-4 col-sm-6 mt-2 mb-1' : 'col-lg-3 col-md-6 col-sm-12 mt-2 mb-1'}
              key={obj.id}>
              <InterviewCard
                status={
                  obj.hasInProgressInterview ? 'Ongoing'
                    : obj.hasUpcomingInterviews ? 'Upcoming'
                      : obj.hasRequestedInterviews ? 'Requested'
                        : undefined
              }
                selected={!!selectedInterviewTypes.filter((e: GetInterviewDto) => e.id === obj.id).length}
                onSelect={onSelect}
                onDeSelect={onDeSelect}
                selectable={props.selectable}
                mode={props.mode}
                loading={loading}
                obj={obj}
                setTriggerInterviewRequest={setTriggerInterviewRequest}/>
            </div>
          ))
          : Array.from({ length: 5 }).map((_, index) => (
            <div className="col-lg-3 col-md-6 col-sm-12 mt-2 mb-1"
              key={index}
              style={{ cursor: 'default' }}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={100}
                sx={{ borderRadius: '10px' }} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default InterviewCards
