import React, { useEffect, useState } from 'react'
import StandardList from './StandardList'
import { type GetInterviewDto, InterviewControllerApi } from '../api/ic'
import ApiManager from '../manager/ApiManager'

function HistoryList () {
  const [history, setHistory] = useState<GetInterviewDto[]>([])

  const interviewApi = ApiManager.getInstance(InterviewControllerApi)

  useEffect(() => {
    void interviewApi.getHistory().then(res => {
      setHistory(res)
    })
  }, [])

  return (
    <StandardList
      rows={history}
      withArrow={true}
      columnNames={[
        // 'rate', // TODO enable when the rating will be available
        'topic',
        'level',
        'recording',
        'giver',
        'taker',
        'language',
        'fullDate',
        'status'
      ]}
      deleteButton={false}/>
  )
}

export default HistoryList
