import React, { useEffect } from 'react'
import Navigation from '../navigation/Navigation'
import { NavigationModeValues } from '../modes/NavigationMode'
import { useParams } from 'react-router-dom'
import ColoredText from '../text/ColoredText'

function InterviewRecordingPage () {
  const { interviewID } = useParams()

  useEffect(() => {
    document.body.style.background = 'rgba(32, 33, 36, 1)'
    return () => { document.body.style.background = 'white' }
  }, [])

  console.log(interviewID)

  return (
    <div>
      <Navigation
        navigationMode={NavigationModeValues.FIXED_WHITE_WITH_INTERVIEW_INFO}
        mobileNavItems={[]}
        height={'100px'}/>
      <div className={'container d-flex justify-content-center align-items-center'} style={{ height: '80vh' }}>
        <ColoredText
          singleText={'Our team is working to make your recording ready, wait a little bit...'}
          color={'white'}/>
      </div>
    </div>
  )
}

export default InterviewRecordingPage
