import React from 'react'
import StandardList from '../../giver/StandardList'
import { type GetInterviewDto } from '../../api/ic'

interface TakerUpcomingListProps {
  upcomingInterviews: GetInterviewDto[]
  setUpdateTimes: React.Dispatch<React.SetStateAction<boolean>>
}

function TakerUpcomingList (props: TakerUpcomingListProps) {
  return (
    <StandardList
      rows={props.upcomingInterviews ?? []}
      withArrow={true}
      eventSetter={props.setUpdateTimes}
      columnNames={['topic',
        'giver',
        'level',
        'language',
        'fullDate',
        'interviewActions',
        'absoluteStatus'
      ]}
      deleteButton={false}/>
  )
}

export default TakerUpcomingList
