/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Question dto
 * @export
 * @interface GetInterviewQuestionDto
 */
export interface GetInterviewQuestionDto {
    /**
     * Question
     * @type {string}
     * @memberof GetInterviewQuestionDto
     */
    content: string;
    /**
     * Hint
     * @type {string}
     * @memberof GetInterviewQuestionDto
     */
    hint: string;
    /**
     * Interview type
     * @type {string}
     * @memberof GetInterviewQuestionDto
     */
    interviewType: string;
    /**
     * Levels
     * @type {Array<string>}
     * @memberof GetInterviewQuestionDto
     */
    levels: Array<GetInterviewQuestionDtoLevelsEnum>;
    /**
     * Type (default: 'system')
     * @type {string}
     * @memberof GetInterviewQuestionDto
     */
    type: string;
    /**
     * Status
     * @type {string}
     * @memberof GetInterviewQuestionDto
     */
    status: GetInterviewQuestionDtoStatusEnum;
    /**
     * Priority
     * @type {number}
     * @memberof GetInterviewQuestionDto
     */
    priority: number;
    /**
     * Interview id
     * @type {string}
     * @memberof GetInterviewQuestionDto
     */
    id: string;
    /**
     * Requires review
     * @type {boolean}
     * @memberof GetInterviewQuestionDto
     */
    requiresReview: boolean;
}


/**
 * @export
 */
export const GetInterviewQuestionDtoLevelsEnum = {
    Beginner: 'BEGINNER',
    Intermediate: 'INTERMEDIATE',
    Pro: 'PRO'
} as const;
export type GetInterviewQuestionDtoLevelsEnum = typeof GetInterviewQuestionDtoLevelsEnum[keyof typeof GetInterviewQuestionDtoLevelsEnum];

/**
 * @export
 */
export const GetInterviewQuestionDtoStatusEnum = {
    Active: 'ACTIVE',
    DisActive: 'DIS_ACTIVE'
} as const;
export type GetInterviewQuestionDtoStatusEnum = typeof GetInterviewQuestionDtoStatusEnum[keyof typeof GetInterviewQuestionDtoStatusEnum];


/**
 * Check if a given object implements the GetInterviewQuestionDto interface.
 */
export function instanceOfGetInterviewQuestionDto(value: object): value is GetInterviewQuestionDto {
    if (!('content' in value) || value['content'] === undefined) return false;
    if (!('hint' in value) || value['hint'] === undefined) return false;
    if (!('interviewType' in value) || value['interviewType'] === undefined) return false;
    if (!('levels' in value) || value['levels'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('priority' in value) || value['priority'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('requiresReview' in value) || value['requiresReview'] === undefined) return false;
    return true;
}

export function GetInterviewQuestionDtoFromJSON(json: any): GetInterviewQuestionDto {
    return GetInterviewQuestionDtoFromJSONTyped(json, false);
}

export function GetInterviewQuestionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInterviewQuestionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'content': json['content'],
        'hint': json['hint'],
        'interviewType': json['interviewType'],
        'levels': json['levels'],
        'type': json['type'],
        'status': json['status'],
        'priority': json['priority'],
        'id': json['id'],
        'requiresReview': json['requiresReview'],
    };
}

export function GetInterviewQuestionDtoToJSON(value?: GetInterviewQuestionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'content': value['content'],
        'hint': value['hint'],
        'interviewType': value['interviewType'],
        'levels': value['levels'],
        'type': value['type'],
        'status': value['status'],
        'priority': value['priority'],
        'id': value['id'],
        'requiresReview': value['requiresReview'],
    };
}

