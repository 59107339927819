import React from 'react'
import { COLORS } from '../util/Consts'
import { capitalizeFirstLetter } from '../util/Helper'

interface InterviewStatusProps {
  interviewStatusString: string | null | undefined
  type: string
}
function InterviewStatus (props: InterviewStatusProps) {
  if (props.type === 'modal') {
    return (
      <span style={{
        marginLeft: '10px',
        borderRadius: '5px',
        background: '#FFF',
        padding: '1px 6px 1px 6px',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
        color: props.interviewStatusString === 'Upcoming' || props.interviewStatusString === 'Ongoing' ? COLORS.MAIN_COLOR : 'black'
      }}>
        {props.interviewStatusString}
      </span>
    )
  }
  if (props.type === 'card') {
    return (
      <span style={{
        position: 'absolute',
        marginLeft: '10px',
        right: '-10px',
        top: '-10px',
        borderRadius: '5px',
        background: '#FFF',
        padding: '1px 6px 1px 6px',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
        color: props.interviewStatusString === 'Upcoming' || props.interviewStatusString === 'Ongoing' ? COLORS.MAIN_COLOR : 'black'
      }}>
        {capitalizeFirstLetter(props.interviewStatusString?.toLowerCase() ?? '')}
      </span>
    )
  }
}

export default InterviewStatus
