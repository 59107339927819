/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InterviewBalanceAndCardDto,
  InterviewBalancePurchaseRequestDto,
  InterviewBalancePurchaseWithBankCardIdRequestDto,
} from '../models/index';
import {
    InterviewBalanceAndCardDtoFromJSON,
    InterviewBalanceAndCardDtoToJSON,
    InterviewBalancePurchaseRequestDtoFromJSON,
    InterviewBalancePurchaseRequestDtoToJSON,
    InterviewBalancePurchaseWithBankCardIdRequestDtoFromJSON,
    InterviewBalancePurchaseWithBankCardIdRequestDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface PurchaseRequest {
    interviewBalancePurchaseRequestDto: InterviewBalancePurchaseRequestDto;
}

export interface PurchaseByBankCardIdRequest {
    interviewBalancePurchaseWithBankCardIdRequestDto: InterviewBalancePurchaseWithBankCardIdRequestDto;
}

/**
 * 
 */
export class InterviewBalanceControllerApi extends runtime.BaseAPI {

    /**
     * Get all interview balance
     * Get all interview balance
     */
    async getAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InterviewBalanceAndCardDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/balance/getAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewBalanceAndCardDtoFromJSON(jsonValue));
    }

    /**
     * Get all interview balance
     * Get all interview balance
     */
    async getAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InterviewBalanceAndCardDto> {
        const response = await this.getAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Purchase interview balance
     * Purchase interview balance
     */
    async purchaseRaw(requestParameters: PurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['interviewBalancePurchaseRequestDto'] == null) {
            throw new runtime.RequiredError(
                'interviewBalancePurchaseRequestDto',
                'Required parameter "interviewBalancePurchaseRequestDto" was null or undefined when calling purchase().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/balance/purchase`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewBalancePurchaseRequestDtoToJSON(requestParameters['interviewBalancePurchaseRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Purchase interview balance
     * Purchase interview balance
     */
    async purchase(requestParameters: PurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.purchaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Purchase interview balance
     * Purchase interview balance
     */
    async purchaseByBankCardIdRaw(requestParameters: PurchaseByBankCardIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['interviewBalancePurchaseWithBankCardIdRequestDto'] == null) {
            throw new runtime.RequiredError(
                'interviewBalancePurchaseWithBankCardIdRequestDto',
                'Required parameter "interviewBalancePurchaseWithBankCardIdRequestDto" was null or undefined when calling purchaseByBankCardId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        await AuthManager.refreshTokenIfNeeded(this)

        const response = await this.request({
            path: `/interview/balance/purchase/withAttachedCard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewBalancePurchaseWithBankCardIdRequestDtoToJSON(requestParameters['interviewBalancePurchaseWithBankCardIdRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Purchase interview balance
     * Purchase interview balance
     */
    async purchaseByBankCardId(requestParameters: PurchaseByBankCardIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.purchaseByBankCardIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
